import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TokenInterceptor } from './shared/interceptors/token.interceptor';
import { SidebarLayoutComponent } from './shared/layouts/sidebar-layout/sidebar-layout.component';
import localeEsAr from '@angular/common/locales/es-AR';
import { registerLocaleData } from '@angular/common';
import { TailNavbarComponent } from './shared/components/tail-navbar/tail-navbar.component';
import { NavbarLayoutComponent } from './shared/layouts/navbar-layout/navbar-layout.component';

import { TooltipDirective } from './shared/directives/tooltip.directive';
import { SharedModule } from './shared/shared.module';
import { MaintenancesPageComponent } from './modules/subscription/pages/maintenances-page/maintenances-page.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import localeEs from '@angular/common/locales/es';
import { ObservationListPopoverComponent } from './shared/components/observation-list-popover/observation-list-popover.component';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { ReportsModule } from './modules/reports/reports.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { DragDropModule } from '@angular/cdk/drag-drop';

registerLocaleData(localeEs);
@NgModule({
  declarations: [
    AppComponent,
    SidebarLayoutComponent,
    TailNavbarComponent,
    NavbarLayoutComponent,
    MaintenancesPageComponent,
    ObservationListPopoverComponent,
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    NgbModule,
    MatPaginatorModule,
    StoreModule.forRoot({}, {}),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    EffectsModule.forRoot([]),
    ReportsModule,
    DragDropModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-Ar' },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ],
  exports: [TooltipDirective],
  bootstrap: [AppComponent],
})
export class AppModule {}
