import { DatePipe, formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import {takeUntil} from "rxjs/operators";
import { SubscriptionService } from 'src/app/modules/subscription/services/subscription.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sells-inform-page',
  templateUrl: './sells-inform-page.component.html',
  styleUrls: ['./sells-inform-page.component.css']
})
export class SellsInformPageComponent implements OnInit {

  destroy$: Subject<any> = new Subject<any>();
  loading = false;
  users: any[] = [];
  suscr: any[] = [];
  dateNow:any= new Date();
  formGroup: FormGroup = this.fb.group({
        estado: ['Solicitado'],
        desde: [''],
        hasta: [''],
    });
  constructor(
    private subscriptionService: SubscriptionService, 
    private route: ActivatedRoute,
    private fb: FormBuilder, 
    private datePipe: DatePipe) { }

  ngOnInit(): void {
    
    this.dateNow =this.datePipe.transform(this.dateNow,"yyyy-MM-dd");
    this.formGroup.controls['desde'].setValue(this.dateNow);
    this.formGroup.controls['hasta'].setValue(this.dateNow);

    this.formGroup

    const queryParams = this.route.snapshot.queryParamMap;

    if(queryParams){
       
      // Verifica si hay parámetros específicos y aplica los filtros
      if (queryParams.has('desde')) {
        this.formGroup.controls['desde'].setValue(queryParams.get('desde'));
      }

      if (queryParams.has('hasta')) {
        this.formGroup.controls['hasta'].setValue(queryParams.get('hasta'));
      }

      if (queryParams.has('estado')) {
        this.formGroup.controls['estado'].setValue(queryParams.get('estado'));
      }
    }


    this.getUsers();
  }
  getUsers() {
    var data = this.formGroup.value;
    this.loading = true;
    this.subscriptionService.sellsByUser(data).pipe(takeUntil(this.destroy$)).subscribe(async res => {
      this.loading = false;
      if(res && res.users){
        this.users = [];
        this.users = res.users;
      }
      if(res && res.suscr){
        this.suscr = [];
        this.suscr = res.suscr;
      }
    }, err => {
      this.loading = false;
      console.log(err);
    });
  }

  filtrar() {
    console.log('filtro', this.formGroup.value);
    this.getUsers();
  }
}
