import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { UserModel } from '../../models/user-model';
import { TailRoute } from '../../models/route';
import { Router, UrlTree } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { SharedService } from '../../services/shared.service';
import { SessionService } from '../../services/session.service';
@Component({
  selector: 'tail-navbar',
  templateUrl: './tail-navbar.component.html',
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          height: '50vh',
        })
      ),
      state(
        'closed',
        style({
          height: '0px',
          display: 'none',
        })
      ),
      transition('open => closed', [animate('0.2s')]),
      transition('closed => open', [
        animate(
          '0s',
          style({
            display: 'block',
          })
        ),
        animate('0.2s'),
      ]),
    ]),
  ],
  styleUrls: ['./tail-navbar.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  },
})
export class TailNavbarComponent implements OnInit {
  environment = environment;
  @Input() routes: TailRoute[] = [];
  @Input() user: UserModel = {
    name: 'Argenfil...',
    email: 'argenfil@argenfil.com.ar',
    company: {
      name: 'Argenfil',
      email: '',
    },
  };
  permisos;
  @Output() userLogout: EventEmitter<any> = new EventEmitter<any>();
  menuState = false;
  notificationsOpen = false;
  showMenu = false;
  constructor(
    private router: Router,
    private _eref: ElementRef,
    private sessionService: SessionService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.sharedService.getPermisos().then((res) => {
      this.permisos = JSON.parse(res);
      if (!this.checkSlug('pageClient')) {
        this.routes = this.routes.filter(function (el) {
          return el.route != '/clients';
        });
      }
      if (!this.checkSlug('paymentProblem')) {
        this.routes.map((rout) => {
          if (rout.children) {
            rout.children = rout.children.filter(function (ch) {
              return ch.route != '/clients/paymentProblems';
            });
          }
        });
      }
      if (!this.checkSlug('pageSubscription')) {
        this.routes = this.routes.filter(function (el) {
          return el.route != '/subscriptions';
        });
      }
      if (!this.checkSlug('pageProduct')) {
        this.routes = this.routes.filter(function (el) {
          return el.route != '/products';
        });
      }
      if (!this.checkSlug('pageComunication')) {
        this.routes = this.routes.filter(function (el) {
          return el.route != '/mails';
        });
      }
      if (!this.checkSlug('pageReport')) {
        this.routes = this.routes.filter(function (el) {
          return el.route != '/reports';
        });
      }
      if (!this.checkSlug('pageUser')) {
        this.routes = this.routes.filter(function (el) {
          return el.route != '/users';
        });
      }
      if (!this.checkSlug('pageProgramming')) {
        this.routes = this.routes.filter(function (el) {
          return el.route != '/programming';
        });
      }
      if (!this.checkSlug('seeInventory')) {
        this.routes = this.routes.filter(function (el) {
          return el.route != '/units';
        });
      }
      if (this.user?.logistic_company && this.user?.logistic_company != null) {
        this.routes = [
          {
            name: 'Agenda',
            icon: 'calendar-outline',
            route: '/programming',
          },
        ];
      }
    });
  }

  checkSlug(permiso) {
    return this.permisos?.some((el) => el.slug == permiso);
  }
  switchMenu(): void {
    this.showMenu = !this.showMenu;
  }
  closeUserMenu(event: any): void {
    if (event.target.id !== 'user-menu-image') {
      this.menuState = false;
    }
  }
  closeNotifications(event: any): void {
    if (event.target.id !== 'notifications-icon' && this.notificationsOpen) {
      this.notificationsOpen = false;
    }
  }
  logoutUser(): void {
    this.userLogout.emit();
  }

  isActive(instruction: string | UrlTree): boolean {
    return this.router.isActive(instruction, false);
  }
  onClick(event) {}
}
