import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PopoverController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Client } from 'src/app/modules/client/interfaces/client';
import { Periodicity, Product } from 'src/app/modules/product/interfaces/product';
import { ProductService } from 'src/app/modules/product/services/product.service';
import { UnsubscribeConfirmationPopoverComponent } from 'src/app/modules/subscription/components/unsubscribe-confirmation-popover/unsubscribe-confirmation-popover.component';
import { SubscriptionService } from 'src/app/modules/subscription/services/subscription.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { ChangeUnsuscribeMotivePopoverComponent } from './change-unsuscribe-motive-popover/change-unsuscribe-motive-popover.component';

@Component({
  selector: 'app-bajas-inform-page',
  templateUrl: './bajas-inform-page.component.html',
  styleUrls: ['./bajas-inform-page.component.css']
})
export class BajasInformPageComponent implements OnInit {

  private unsubscribeAll: Subject<any>;
  destroy$: Subject<any> = new Subject<any>();
  loading = true;
  users: any[] = [];
  suscr: any[] = [];
  dateNow: any = new Date();
  clients: Client[] = [];
  products: Product[] = [];
  periodicitys: Periodicity[] = [];
  formGroup: FormGroup = this.fb.group({
    from: [''],
    to: [''],
    products: [''],
    periodicity_id: ['']
  });
  unsubscribeMotives: any[] = [];
  constructor(private _sharedService: SharedService,
    private subscriptionService: SubscriptionService,
    private _popoverController: PopoverController,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private productService: ProductService,
  ) {
    this.unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.productService.getProducts().pipe(takeUntil(this.unsubscribeAll)).subscribe(res => {
      this.products = res;
    });

    this.productService.getPeriodicity().pipe(takeUntil(this.unsubscribeAll)).subscribe(res => {
      this.periodicitys = res;
    });
    this._sharedService.getUnsubscribeMotives().pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.unsubscribeMotives = res;
    });

    // this.dateNow = this.datePipe.transform(this.dateNow, "yyyy-MM-dd");
    // this.formGroup.controls['from'].setValue(this.dateNow);
    // this.formGroup.controls['to'].setValue(this.dateNow);

    this.getData();
  }

  getData() {
    var data = this.formGroup.value;
    this.loading = true;
    this.subscriptionService.informeBajas(data).pipe(takeUntil(this.destroy$)).subscribe(async res => {
      this.loading = false;
      if (res && res.report) {
        this.suscr = [];
        this.suscr = res.report;
      }
    }, err => {
      this.loading = false;
      console.log(err);
    });
  }

  filtrar() {
    console.log('filtro', this.formGroup.value);
    this.getData();
  }
  downloadFile() {
    var data = this.formGroup.value
    data['excelExport'] = true;
    this.subscriptionService.informeBajasExcel(data).subscribe(res => {
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(res);
      link.download = 'Informe de bajas';
      link.click();
    });
  }
  async presentUnsuscribeMotivePopover(sus) {
    const modal = await this._popoverController.create({
      component: ChangeUnsuscribeMotivePopoverComponent,
      cssClass: 'full-popover sm:rounded',
      backdropDismiss: false,
      componentProps: {
        subscription: sus
      }
    });
    await modal.present();
    modal.onDidDismiss().then(r => {
      this.getData();
    }).catch(err => {
      console.log(err);
    });

  }
}