<div *ngIf="loading" class="text-center flex items-center justify-center mx-auto">
    <mat-spinner></mat-spinner>
</div>
<ion-header>
    <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button (click)="closePopover()">Cerrar</ion-button>
        </ion-buttons>
        <ion-title>Cambiar motivo de baja</ion-title>
    </ion-toolbar>

</ion-header>
<div class="py-2 px-10">
    <div>
        <p><span class="font-semibold">Seleccione el motivo de baja</span></p>
    </div>

    <select class="form-control text-base" name="periodicity_id" [(ngModel)]="subscription.unsubscribe_motive_id">
        <option [value]="motive?.id" *ngFor="let motive of unsubscribeMotives">{{motive?.name}}</option>
    </select>
</div>
<div class="modal-footer">
    <button class="font-semibold text-gray-600" (click)="closePopover()">Cancelar</button>
    <button type="button" (click)="cambiarMotivo()" class="px-4 py-2 text-white font-semibold bg-blue-500 rounded">
        Guardar
    </button>
</div>