import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Client } from '../interfaces/client';
import { ClientFilters } from '../interfaces/clientFilters';
import { ClientPaymentMethod } from '../../subscription/interfaces/subscription';
import { Invoice } from '../interfaces/invoice';
import { SubscriptionChanges } from '../../subscription/interfaces/subscription-changes';
import { ClientChanges } from '../interfaces/client-changes';
import { Mail } from '../../mail/interfaces/communication';

@Injectable({
  providedIn: 'root',
})
export class ClientService implements Resolve<any> {
  private apiUrl = environment.api_url;
  routeParams: any;
  onClientChange: BehaviorSubject<Client>;
  client: Client;

  initialClient = {
    id: null,
    company_id: null,
    name: '',
    person_type: 'PERSONA FISICA',
    email: '',
    gender: 'Masculino',
    iva_exempt: false,
    phones: [
      {
        type: 'Celular',
        number: '',
      },
    ],
    documents: [
      {
        type: 'dni',
        number: '',
      },
    ],
  };
  constructor(private httpClient: HttpClient) {
    this.onClientChange = new BehaviorSubject(this.initialClient);
  }

  /**
   * Resolver
   *
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    this.routeParams = route.params;

    return new Promise((resolve, reject) => {
      Promise.all([this.loadClient()]).then(() => {
        resolve(true);
      }, reject);
    });
  }

  storeClient(client) {
    return this.httpClient.post<Client>(`${this.apiUrl}client`, client);
  }

  updateClient(client: Client) {
    return this.httpClient.put<Client>(
      `${this.apiUrl}client/${client.id}`,
      client
    );
  }
  loadClient(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.routeParams.id === 'new') {
        this.onClientChange.next(this.initialClient);
        resolve(false);
      } else {
        this.getClient(this.routeParams.id).then((res) => {
          resolve(res);
        }, reject);
      }
    });
  }
  getClientChanges(id): Promise<ClientChanges[]> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get<any>(`${this.apiUrl}changes/client/${id}`)
        .subscribe((items) => {
          const changeList = [];
          items.forEach((res) => {
            const changes: ClientChanges = {
              id: res.id,
              user_id: res.user_id,
              user: res.user,
              model: res.model,
              action: res.action,
              data: JSON.parse(res.data),
              created_at: res.created_at,
              updated_at: res.updated_at,
            };
            changeList.push(changes);
          });
          resolve(changeList);
        }, reject);
    });
  }
  getClientBills(id): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get<any>(`${this.apiUrl}client/${id}/bills`)
        .subscribe((items) => {
          const billList = [];
          items.forEach((res) => {
            const bills: any = res;
            billList.push(bills);
          });
          resolve(billList);
        }, reject);
    });
  }
  downloadClientBills(id: string): void {
    const url = `${this.apiUrl}clients/${id}/download-bills`;
    this.httpClient.get(url, { responseType: 'blob' }).subscribe((response: Blob) => {
      const url = window.URL.createObjectURL(response);
      const a = document.createElement('a');
      a.href = url;
      a.download = `bills_${id}.zip`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    });
  }
  getClient(id): Promise<Client> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get<Client>(`${this.apiUrl}client/${id}`)
        .subscribe((res) => {
          this.client = res;
          this.onClientChange.next(this.client);
          resolve(res);
        }, reject);
    });
  }
  getInvoicesByClient(id) {
    return this.httpClient.get<any>(`${this.apiUrl}invoices/client/${id}`);
  }
  getMailsByClientId(id) {
    return this.httpClient.get<Mail[]>(
      `${this.apiUrl}getMailsByClientId/${id}`
    );
  }
  getInvoices(data) {
    return this.httpClient.post<any>(`${this.apiUrl}invoices/inform`, data);
  }
  getInvoicesExcel(data) {
    return this.httpClient.post(`${this.apiUrl}invoices/inform`, data, {
      responseType: 'blob',
    });
  }
  getTotalsByClient(id) {
    return this.httpClient.get<any>(`${this.apiUrl}invoices/totals/${id}`);
  }
  getDebtByClient(id) {
    return this.httpClient.get<number>(
      `${this.apiUrl}invoices/getDebtByClient/${id}`
    );
  }
  markAsPaymentProblems(id, payment_problems) {
    const data = {
      payment_problems,
    };
    return this.httpClient.post<any>(
      `${this.apiUrl}client/markAsPaymentProblem/${id}`,
      data
    );
  }
  markClientsAsPaymentProblems(ids) {
    const data = {
      ids,
    };
    return this.httpClient.post<any>(
      `${this.apiUrl}client/markPaymentProblem`,
      data
    );
  }
  storeInvoice(invoice: Invoice) {
    const data = {
      id: invoice.id,
      type: invoice.type,
      amount: invoice.amount,
      subscription_id: invoice.subscription_id,
      client_id: invoice.client_id,
      invoice_id: invoice.invoice_id,
      client_payment_method_id: invoice.client_payment_method_id,
      registered: invoice.registered,
      notified: invoice.notified,
      issued_at: invoice.issued_at?.toISOString(),
      expires_at: invoice.expires_at?.toISOString(),
      concept: invoice.concept,
    };
    return this.httpClient.post<Client>(`${this.apiUrl}invoices`, data);
  }
  updateInvoice(invoice: Invoice) {
    const data = {
      id: invoice.id,
      type: invoice.type,
      amount: invoice.amount,
      subscription_id: invoice.subscription_id,
      client_id: invoice.client_id,
      invoice_id: invoice.invoice_id,
      client_payment_method_id: invoice.client_payment_method_id,
      registered: invoice.registered,
      notified: invoice.notified,
      issued_at: invoice.issued_at?.toISOString(),
      expires_at: invoice.expires_at?.toISOString(),
    };
    return this.httpClient.put<Client>(
      `${this.apiUrl}invoices/${data.id}`,
      data
    );
  }
  getClients() {
    return this.httpClient.get<Client[]>(`${this.apiUrl}client`);
  }
  filterClients(filters: ClientFilters) {
    let params = '';
    if (filters.name.filter && filters.name.query !== '') {
      params += params === '' ? '?' : '&';
      params += `name=${filters.name.query}`;
    }
    if (filters.document.filter && filters.document.query !== '') {
      params += params === '' ? '?' : '&';
      params += `document=${filters.document.query}`;
    }
    if (
      filters.payment_problems.filter &&
      filters.payment_problems.query !== ''
    ) {
      params += params === '' ? '?' : '&';
      params += `payment_problems=${filters.payment_problems.query}`;
    }
    if (
      filters.payment_problems_min_months.filter &&
      filters.payment_problems_min_months.query !== ''
    ) {
      params += params === '' ? '?' : '&';
      params += `payment_problems_min_months=${filters.payment_problems_min_months.query}`;
    }
    if (
      filters.payment_problems_max_months.filter &&
      filters.payment_problems_max_months.query !== ''
    ) {
      params += params === '' ? '?' : '&';
      params += `payment_problems_max_months=${filters.payment_problems_max_months.query}`;
    }
    if (filters.email.filter && filters.email.query !== '') {
      params += params === '' ? '?' : '&';
      params += `email=${filters.email.query}`;
    }
    if (filters.user.filter && filters.user.query !== '') {
      params += params === '' ? '?' : '&';
      params += `user=${filters.user.query}`;
    }
    if (filters.address.filter && filters.address.query !== '') {
      params += params === '' ? '?' : '&';
      params += `address=${filters.address.query}`;
    }
    if (filters.postalCode.filter && filters.postalCode.query !== '') {
      params += params === '' ? '?' : '&';
      params += `postalCode=${filters.postalCode.query}`;
    }
    if (filters.phone.filter && filters.phone.query !== '') {
      params += params === '' ? '?' : '&';
      params += `phone=${filters.phone.query}`;
    }
    if (filters.with_referrers.filter && filters.with_referrers.query !== '') {
      params += params === '' ? '?' : '&';
      params += `with_referrers=${filters.with_referrers.query}`;
    }
    if (filters.referred_by.filter && filters.referred_by.query !== '') {
      params += params === '' ? '?' : '&';
      params += `referred_by=${filters.referred_by.query}`;
    }
    if (filters.leads.filter && filters.leads.query !== '') {
      params += params === '' ? '?' : '&';
      params += `leads=${filters.leads.query}`;
    }
    return this.httpClient.get<Client[]>(`${this.apiUrl}client${params}`);
  }

  editPaymentMethod(method: ClientPaymentMethod) {
    return this.httpClient.put<ClientPaymentMethod>(
      `${this.apiUrl}clientPaymentMethod/${method.id}`,
      method
    );
  }
  storePaymentMethod(method: ClientPaymentMethod) {
    return this.httpClient.post<ClientPaymentMethod>(
      `${this.apiUrl}clientPaymentMethod`,
      method
    );
  }
  sendEmail(id) {
    return this.httpClient.get<any>(`${this.apiUrl}bills/${id}/sendEmail`);
  }
  getAttachedTypes() {
    return this.httpClient.get<any[]>(`${this.apiUrl}files/attachedTypes`);
  }
  sendDocumentFormData(formData: FormData) {
    return this.httpClient.post<any>(
      `${this.apiUrl}client/storeDocument`,
      formData
    );
  }
  updateDocument(formData: FormData) {
    formData.append('_method', 'PUT');
    return this.httpClient.post<any>(
      `${this.apiUrl}client/updateDocument/${formData.get('client_id')}`,
      formData
    );
  }
  getDocuments(client: Client) {
    return this.httpClient.get<any[]>(
      `${this.apiUrl}client/${client.id}/listDocument`
    );
  }
  downloadDocument(idDocument: string): Observable<HttpResponse<Blob>> {
    return this.httpClient.get(
      `${this.apiUrl}client/downloadDocument/${idDocument}`,
      {
        observe: 'response',
        responseType: 'blob',
      }
    );
  }
  deleteDocument(idDocument: string) {
    return this.httpClient.delete(
      `${this.apiUrl}client/deleteDocument/${idDocument}`
    );
  }
  saveContactedBy(clientId, selectedContactBy) {
    const data = {
      client_id: clientId,
      selectedContactBy,
    };
    return this.httpClient.post<any>(
      `${this.apiUrl}client/saveContactedBy`,
      data
    );
  }
  searchClients(query: string): Observable<Client[]> {
    const url = `${this.apiUrl}client/searchClients`;
    const body = { query };
    return this.httpClient.post<Client[]>(url, body);
  }
}
