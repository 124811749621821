<li id="listbox-item-0" role="option" class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 hover:bg-blue-200 group">
  <div class="flex items-center flex-wrap">
    <div class="flex items-center flex-shrink-0 h-6 w-6 rounded-full justify-center bg-gray-200 group-hover:text-black">
      {{client.name[0]}}
    </div>
    <span class="ml-3 block {{selected ? 'font-semibold' : 'font-normal'}} truncate dark:text-gray-300"> {{client.name}} </span>
    <span *ngIf="client.document != 'Sin Documentos'" class="ml-3 block {{selected ? 'font-semibold' : 'font-normal'}} truncate dark:text-gray-300"> ({{client.document}}) </span>
    <span *ngIf="client.phone != 'Sin Teléfonos'" class="ml-3 block {{selected ? 'font-semibold' : 'font-normal'}} truncate dark:text-gray-300">Tel.: {{client.phone}} </span>
    <span *ngIf="client.address != 'Sin Direcciones' " class="ml-3 block {{selected ? 'font-semibold' : 'font-normal'}} truncate dark:text-gray-300">Direccion: {{client.address}} </span>
    <span *ngIf="client.email" class="ml-3 block {{selected ? 'font-semibold' : 'font-normal'}} truncate dark:text-gray-300"> {{client.email}} </span>
  </div>

  <!--
    Checkmark, only display for selected option.

    Highlighted: "text-white", Not Highlighted: "text-indigo-600"
  -->
  <span *ngIf="selected" class="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-hover:text-white">
    <!-- Heroicon name: solid/check -->
    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
    </svg>
  </span>
</li>
