import {Component, Input, OnInit} from '@angular/core';
import {Client} from '../../../client/interfaces/client';

@Component({
  selector: 'app-client-selector-item',
  templateUrl: './client-selector-item.component.html',
  styleUrls: ['./client-selector-item.component.scss'],
})
export class ClientSelectorItemComponent implements OnInit {

  @Input() selected = false;
  @Input() client: Client;

  constructor() { }

  ngOnInit() {
  }

}
