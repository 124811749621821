import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Subscription } from '../interfaces/subscription';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { NavController } from '@ionic/angular';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { ClientFilters } from '../../client/interfaces/clientFilters';
import { Client } from '../../client/interfaces/client';
import { SubscriptionFilters } from '../interfaces/subscription-filters';
import { SubscriptionChanges } from '../interfaces/subscription-changes';
import { StopDebit } from '../interfaces/stop-debit';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService implements Resolve<any> {
  private apiUrl = environment.api_url;
  subscriptions: Subscription[];
  onSubscriptionsChange: BehaviorSubject<Subscription[]>;
  routeParams: any;
  constructor(
    private httpClient: HttpClient,
    private navController: NavController
  ) {
    this.onSubscriptionsChange = new BehaviorSubject<Subscription[]>([]);
  }
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    this.routeParams = route.params;
    return new Promise((resolve, reject) => {
      Promise.all([this.loadSubscriptions()]).then(() => {
        resolve(true);
      }, reject);
    });
  }

  loadSubscriptions() {
    return new Promise((resolve, reject) => {
      this.getSubscriptions().then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  getSubscriptionsObservable() {
    return this.httpClient.get<Subscription[]>(`${this.apiUrl}subscription`);
  }
  getSubscriptions(): Promise<Subscription[]> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get<Subscription[]>(`${this.apiUrl}subscription`)
        .subscribe((res) => {
          // Mapeo de propiedades adicionales
          const mappedSubscriptions = res.map((sub) => ({
            ...sub,
            name: sub.client?.name || '',
            clientPaymentMethodName: sub.client_payment_method?.name || '',
            productName: sub.product?.name || '',
            adressName: sub.address?.address || '',
            provinceName: sub.address?.province || '',
          }));

          this.subscriptions = mappedSubscriptions;
          this.onSubscriptionsChange.next(this.subscriptions);
          resolve(mappedSubscriptions);
        }, reject);
    });
  }
  deleteSubscription(id) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .delete(`${this.apiUrl}subscription/${id}`)
        .subscribe((res) => {
          resolve(res);
        }, reject);
    });
  }

  filterSubscriptions(filters: SubscriptionFilters) {
    let params = '';
    if (filters.client.filter && filters.client.query !== '') {
      params += params === '' ? '?' : '&';
      params += `client=${filters.client.query}`;
    }
    if (filters.product.filter && filters.product.query !== '') {
      let i = 0;
      filters.product.query.forEach((item) => {
        params += params === '' ? '?' : '&';
        params += `product[${i}]=${item}`;
        i = i + 1;
      });
    }
    if (filters.periodicity.filter && filters.periodicity.query !== '') {
      params += params === '' ? '?' : '&';
      params += `periodicity=${filters.periodicity.query}`;
    }
    if (filters.increase_date.filter && filters.increase_date.query !== '') {
      params += params === '' ? '?' : '&';
      params += `increase_date=${filters.increase_date.query}`;
    }
    if (filters.status.filter && filters.status.query !== '') {
      params += params === '' ? '?' : '&';
      params += `status=${filters.status.query}`;
    }
    if (filters.actual_price.filter && filters.actual_price.query !== '') {
      params += params === '' ? '?' : '&';
      params += `periodic_price=${filters.actual_price.query}`;
    }
    return this.httpClient.get<Subscription[]>(
      `${this.apiUrl}subscription${params}`
    );
  }
  increasePrices(data) {
    return this.httpClient.post<any>(
      `${this.apiUrl}prices/increasePrices`,
      data
    );
  }
  sellsByUser(data) {
    return this.httpClient.post<any>(`${this.apiUrl}sells/sellsByUser`, data);
  }
  inform(data) {
    let params = '';
    if (data.from && data.from != '') {
      params += params === '' ? '?' : '&';
      params += `from=${data.from}`;
    }
    if (data.to && data.to != '') {
      params += params === '' ? '?' : '&';
      params += `to=${data.to}`;
    }
    if (data.clients && data.clients != '') {
      params += params === '' ? '?' : '&';
      params += `clients=${data.clients}`;
    }
    if (data.statuses && data.statuses != '') {
      params += params === '' ? '?' : '&';
      params += `statuses=${data.statuses}`;
    }
    if (data.ignore_refered && data.ignore_refered != '') {
      params += params === '' ? '?' : '&';
      params += `ignore_refered=${data.ignore_refered}`;
    }
    if (data.payment_method && data.payment_method != '') {
      params += params === '' ? '?' : '&';
      params += `payment_method=${data.payment_method}`;
    }
    if (data.veraz_score && data.veraz_score != '') {
      params += params === '' ? '?' : '&';
      params += `veraz_score=${data.veraz_score}`;
    }
    if (data.veraz_score_comp && data.veraz_score_comp != '') {
      params += params === '' ? '?' : '&';
      params += `veraz_score_comp=${data.veraz_score_comp}`;
    }
    if (data.products && data.products != '') {
      params += params === '' ? '?' : '&';
      params += `products=${data.products}`;
    }
    if (data.periodicity_id && data.periodicity_id != '') {
      params += params === '' ? '?' : '&';
      params += `periodicity_id=${data.periodicity_id}`;
    }
    if (data.city && data.city != '') {
      params += params === '' ? '?' : '&';
      params += `city=${data.city}`;
    }
    console.log('data', data);

    return this.httpClient.get<any>(
      `${this.apiUrl}subscription/informe${params}`
    );
  }
  informExcel(data) {
    let params = '';
    if (data.from && data.from != '') {
      params += params === '' ? '?' : '&';
      params += `from=${data.from}`;
    }
    if (data.to && data.to != '') {
      params += params === '' ? '?' : '&';
      params += `to=${data.to}`;
    }
    if (data.clients && data.clients != '') {
      params += params === '' ? '?' : '&';
      params += `clients=${data.clients}`;
    }
    if (data.statuses && data.statuses != '') {
      params += params === '' ? '?' : '&';
      params += `statuses=${data.statuses}`;
    }
    if (data.ignore_refered && data.ignore_refered != '') {
      params += params === '' ? '?' : '&';
      params += `ignore_refered=${data.ignore_refered}`;
    }
    if (data.payment_method && data.payment_method != '') {
      params += params === '' ? '?' : '&';
      params += `payment_method=${data.payment_method}`;
    }
    if (data.veraz_score && data.veraz_score != '') {
      params += params === '' ? '?' : '&';
      params += `veraz_score=${data.veraz_score}`;
    }
    if (data.veraz_score_comp && data.veraz_score_comp != '') {
      params += params === '' ? '?' : '&';
      params += `veraz_score_comp=${data.veraz_score_comp}`;
    }
    if (data.products && data.products != '') {
      params += params === '' ? '?' : '&';
      params += `products=${data.products}`;
    }
    if (data.periodicity_id && data.periodicity_id != '') {
      params += params === '' ? '?' : '&';
      params += `periodicity_id=${data.periodicity_id}`;
    }
    if (data.city && data.city != '') {
      params += params === '' ? '?' : '&';
      params += `city=${data.city}`;
    }
    params += params === '' ? '?' : '&';
    params += `excelExport=true`;

    return this.httpClient.get(`${this.apiUrl}subscription/informe${params}`, {
      responseType: 'blob',
    });
  }
  informeBajas(data) {
    let params = '';
    if (data.from && data.from != '') {
      params += params === '' ? '?' : '&';
      params += `from=${data.from}`;
    }
    if (data.to && data.to != '') {
      params += params === '' ? '?' : '&';
      params += `to=${data.to}`;
    }
    if (data.clients && data.clients != '') {
      params += params === '' ? '?' : '&';
      params += `clients=${data.clients}`;
    }
    if (data.statuses && data.statuses != '') {
      params += params === '' ? '?' : '&';
      params += `statuses=${data.statuses}`;
    }
    if (data.ignore_refered && data.ignore_refered != '') {
      params += params === '' ? '?' : '&';
      params += `ignore_refered=${data.ignore_refered}`;
    }
    if (data.payment_method && data.payment_method != '') {
      params += params === '' ? '?' : '&';
      params += `payment_method=${data.payment_method}`;
    }
    if (data.veraz_score && data.veraz_score != '') {
      params += params === '' ? '?' : '&';
      params += `veraz_score=${data.veraz_score}`;
    }
    if (data.veraz_score_comp && data.veraz_score_comp != '') {
      params += params === '' ? '?' : '&';
      params += `veraz_score_comp=${data.veraz_score_comp}`;
    }
    if (data.products && data.products != '') {
      params += params === '' ? '?' : '&';
      params += `products=${data.products}`;
    }
    if (data.periodicity_id && data.periodicity_id != '') {
      params += params === '' ? '?' : '&';
      params += `periodicity_id=${data.periodicity_id}`;
    }
    if (data.city && data.city != '') {
      params += params === '' ? '?' : '&';
      params += `city=${data.city}`;
    }
    console.log('data', data);

    return this.httpClient.get<any>(
      `${this.apiUrl}subscription/informeBajas${params}`
    );
  }
  informeBajasExcel(data) {
    let params = '';
    if (data.from && data.from != '') {
      params += params === '' ? '?' : '&';
      params += `from=${data.from}`;
    }
    if (data.to && data.to != '') {
      params += params === '' ? '?' : '&';
      params += `to=${data.to}`;
    }
    if (data.clients && data.clients != '') {
      params += params === '' ? '?' : '&';
      params += `clients=${data.clients}`;
    }
    if (data.statuses && data.statuses != '') {
      params += params === '' ? '?' : '&';
      params += `statuses=${data.statuses}`;
    }
    if (data.ignore_refered && data.ignore_refered != '') {
      params += params === '' ? '?' : '&';
      params += `ignore_refered=${data.ignore_refered}`;
    }
    if (data.payment_method && data.payment_method != '') {
      params += params === '' ? '?' : '&';
      params += `payment_method=${data.payment_method}`;
    }
    if (data.veraz_score && data.veraz_score != '') {
      params += params === '' ? '?' : '&';
      params += `veraz_score=${data.veraz_score}`;
    }
    if (data.veraz_score_comp && data.veraz_score_comp != '') {
      params += params === '' ? '?' : '&';
      params += `veraz_score_comp=${data.veraz_score_comp}`;
    }
    if (data.products && data.products != '') {
      params += params === '' ? '?' : '&';
      params += `products=${data.products}`;
    }
    if (data.periodicity_id && data.periodicity_id != '') {
      params += params === '' ? '?' : '&';
      params += `periodicity_id=${data.periodicity_id}`;
    }
    if (data.city && data.city != '') {
      params += params === '' ? '?' : '&';
      params += `city=${data.city}`;
    }
    params += params === '' ? '?' : '&';
    params += `excelExport=true`;

    return this.httpClient.get(
      `${this.apiUrl}subscription/informeBajas${params}`,
      { responseType: 'blob' }
    );
  }

  stopDebit(subscriptionId: number, periods: number): Observable<any> {
    const data = {
      subscription_id: subscriptionId,
      periods: periods,
    };

    return this.httpClient.post<any>(
      `${this.apiUrl}subscription/stop-debit`,
      data
    );
  }

  getStopDebits(): Observable<StopDebit[]> {
    return this.httpClient.get<StopDebit[]>(`${this.apiUrl}stopDebits`);
  }
}
