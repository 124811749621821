import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as fromShared from '../actions/shared.actions';
import {catchError, concatMap, map} from 'rxjs/operators';
import {of} from 'rxjs';
import {SharedService} from "../../services/shared.service";

@Injectable()
export class SharedEffects {
  loadProducts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromShared.loadProducts),
      concatMap((_) => {
        return this._sharedService.getProducts()
          .pipe(
            map(data => (fromShared.loadProductsSuccess({ data }))),
            catchError(error => of(fromShared.loadProductsFailure({ error })))
          );
      })
    );
  });

  constructor(private actions$: Actions, private _sharedService: SharedService) {
  }
}
