import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../app-state';
import * as ReportActions from '../../../modules/reports/store/actions/report.actions';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
@Component({
  selector: 'app-wizard-step',
  templateUrl: './wizard-step.component.html',
  styleUrls: ['./wizard-step.component.css']
})
export class WizardStepComponent implements OnInit, OnDestroy {

  step = 0;
  _unsubscribeAll: Subject<any>;
  @Input() steps: string[] = [];
  constructor(private store: Store<AppState>) {
    this._unsubscribeAll = new Subject<any>();
  }

  ngOnInit(): void {
    this.store.select('reports').pipe(takeUntil(this._unsubscribeAll)).subscribe(state => {
      this.step = state.step;
    })
  }

  onStepChange(step: number): void {
    this.store.dispatch(ReportActions.changeStep({step}));
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
