import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { StopDebit } from '../../interfaces/stop-debit';
import { SubscriptionService } from '../../services/subscription.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlEs } from 'src/app/modules/client/services/mat-paginator-intl';
import { MatSort } from '@angular/material/sort';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-stop-debit-table',
  templateUrl: './stop-debit-table.component.html',
  styleUrls: ['./stop-debit-table.component.css'],
})
export class StopDebitTableComponent implements OnInit {
  dataSource: MatTableDataSource<StopDebit>;
  displayedColumns: string[] = [
    'subscriptionID',
    'clientName',
    'subscriptionProduct',
    'subscriptionPrice',
    'periods',
    'activateAt',
    'userName',
    /* 'actions' */
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private stopDebitService: SubscriptionService,
    private paginate: MatPaginatorIntl,
    private paginateEs: MatPaginatorIntlEs,
    private location: Location,
    private router: Router
  ) {
    this.dataSource = new MatTableDataSource<StopDebit>();
    this.paginate.itemsPerPageLabel = this.paginateEs.itemsPerPageLabel;
    this.paginate.previousPageLabel = this.paginateEs.previousPageLabel;
    this.paginate.nextPageLabel = this.paginateEs.nextPageLabel;
    this.paginate.getRangeLabel = this.paginateEs.getRangeLabel;
  }

  loading: boolean = false;

  ngOnInit(): void {
    this.getStopDebit();
  }

  goBack(): void {
    this.location.back();
  }

  getStopDebit(): void {
    this.loading = true;
    this.stopDebitService.getStopDebits().subscribe(
      (data: StopDebit[]) => {
        this.dataSource.data = data;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loading = false;
      },
      (error) => {
        Swal.fire({
          icon: 'error',
          title: 'Error obteniendo los stop debits.',
        });
        this.loading = false;
      }
    );
  }

  goToDetail(id: number) {
    this.router.navigate([`subscriptions/detail/${id}`]);
  }
}
