<div class="bg-white rounded-md shadow">
    <form [formGroup]="formGroup">
        <h1 class="flex items-center mx-5 sm:mx-10 justify-between text-xl font-semibold text-blue-900 py-4">
            Cuenta corriente
        </h1>
        <div class="filtros flex-wrap items-end justify-around px-8">
            <div class="form-group">
                <strong>Salida por</strong>
                <select class="form-control text-base" formControlName="salida" name="salida">
                    <!-- Remove this if you want a continuous filter -->
                    <option></option>
                    <option value="cliente">Cliente</option>
                    <option value="suscripcion">Suscripcion</option>
                </select>
            </div>
            <div class="form-group">
                <strong>Tipo de producto</strong>
                <select class="form-control text-base" formControlName="producto" name="producto">
                    <option></option>
                    <option *ngFor="let p of productos" [value]="p">{{p}}</option>
                </select>
            </div>
            <div class="form-group">
                <strong>Cliente</strong>
                <app-client-selector (clientSelected)="selectClient($event)" [clients]="clients">
                </app-client-selector>
            </div>
            <div class="form-group">
                <strong>Desde</strong>
                <input type="date" name="from" formControlName="from" class="form-control appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent flex-1"
                />
            </div>
            <div class="form-group">
                <strong>Hasta</strong>
                <input type="date" name="to" formControlName="to" class="form-control appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent flex-1"
                />
            </div>
            <div class="flex flex-1 form-group items-end">
                <ion-button size="small" (click)="filtrar()" class="button button-small button-solid capitalize h-9 hydrated ion-activatable ion-focusable md my-0">
                    Filtrar
                    <ion-icon name="filter">
                    </ion-icon>
                </ion-button>
                <ion-button size="small" (click)="downloadFile()" class="button button-small button-solid capitalize h-9 hydrated ion-activatable ion-focusable md my-0">
                    Descargar
                    <ion-icon name="cloud-download-outline">
                    </ion-icon>
                </ion-button>
            </div>
        </div>
        <table class="hidden sm:table min-w-full table-auto">
            <thead class="text-sm">
                <tr class="bg-gray-800">
                    <th class="py-2"><span class="text-gray-300">Cliente</span></th>
                    <th class="py-2"><span class="text-gray-300">Suscripciones</span></th>
                    <th class="py-2"><span class="text-gray-300">Total</span></th>
                </tr>
            </thead>
            <tbody *ngIf="invoices?.length > 0 ;else other_content" class="bg-gray-200">
                <tr class="bg-white border-4 border-gray-200 text-sm" *ngFor="let invoice of invoices">
                    <td class="text-center">
                        <span class="text-center ml-2">{{invoice.cliente}}</span>
                    </td>
                    <td class="text-center">
                        <span class="text-center ml-2" *ngFor="let s of invoice.suscripciones">({{s.id}}){{s.product_name}}</span>
                    </td>
                    <td class="text-center">
                        <span class="text-center ml-2">$ {{invoice.total}}</span>
                    </td>
                </tr>
            </tbody>
            <ng-template #other_content>
                <tbody class="bg-gray-200">
                    <tr class="bg-white border-4 border-gray-200 text-sm">
                        <td colspan="3" class="text-center">
                            <h1 class="text-xl text-center ml-2 font-semibold">No hay datos</h1>
                        </td>
                    </tr>
                </tbody>
            </ng-template>
        </table>
    </form>
</div>