import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../app-state';
import * as fromReport from '../../store/selectors/report.selectors';
import * as reportActions from '../../store/actions/report.actions';
import {takeUntil} from 'rxjs/operators';
import {Report} from '../../interfaces/report';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {PopoverController} from '@ionic/angular';
import {ReportPreviewPopoverComponent} from '../../components/report-preview-popover/report-preview-popover.component';
import {MatDialog} from '@angular/material/dialog';
@Component({
  selector: 'app-reports-page',
  templateUrl: './reports-page.component.html',
  styleUrls: ['./reports-page.component.css']
})
export class ReportsPageComponent implements OnInit, OnDestroy, AfterViewInit {

  private _unsubscribeAll: Subject<any>;

  displayedColumns: string[] = ['id', 'name', 'created_at', 'actions'];
  dataSource = new MatTableDataSource<Report>([]);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private store: Store<AppState>, private popoverController: PopoverController, private matDialog: MatDialog) {
    this._unsubscribeAll = new Subject<any>();
    this.store.select(fromReport.selectReports).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
      this.dataSource.data = res;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }
  ngAfterViewInit() {

  }
  ngOnInit(): void {
    this.store.dispatch(reportActions.loadReports());
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  async openReportPreview(data) {
    const dialogRef = this.matDialog.open(ReportPreviewPopoverComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

}
