import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Client} from '../../../../client/interfaces/client';
import {ClientFilters} from '../../../../client/interfaces/clientFilters';
import {Subject} from 'rxjs';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../app-state';
import {PopoverController} from '@ionic/angular';
import * as fromReport from '../../../store/selectors/report.selectors';
import {takeUntil} from 'rxjs/operators';
import {FilterClientsPopoverComponent} from '../../../../client/components/filter-clients-popover/filter-clients-popover.component';
import {changeClientFilters, changeInvoiceFilters} from '../../../store/actions/report.actions';
import {InvoiceFilters} from '../../../../invoice/interfaces/payment';
import {FilterInvoicesPopoverComponent} from '../../../../invoice/components/filter-invoices-popover/filter-invoices-popover.component';
import {Invoice} from '../../../../client/interfaces/invoice';

@Component({
  selector: 'app-report-step-two-invoices',
  templateUrl: './report-step-two-invoices.component.html',
  styleUrls: ['./report-step-two-invoices.component.css']
})
export class ReportStepTwoInvoicesComponent implements AfterViewInit, OnInit, OnDestroy {

  displayedColumns: string[] = ['id', 'client', 'type', 'amount', 'period', 'created_at'];
  dataSource = new MatTableDataSource<Invoice>([]);
  filters: InvoiceFilters;
  filterBags = [];
  invoice: Invoice[];
  test: any;
  private _unsubscribeAll: Subject<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    if (!this.dataSource.sort) {
      this.dataSource.sort = sort;
    }
  }
  constructor(private store: Store<AppState>, private popoverController: PopoverController) {
    this._unsubscribeAll = new Subject<any>();
    this.store.select(fromReport.selectReportInvoices).pipe(takeUntil(this._unsubscribeAll)).subscribe(invoices => {
      this.dataSource.data = invoices;
    })
    this.store.select(fromReport.selectInvoiceFilters).pipe(takeUntil(this._unsubscribeAll)).subscribe(filters => {
      this.filters = JSON.parse(JSON.stringify(filters));
      this.generateBags();
    })
  }



  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;

  }
  ngOnInit(): void {
  }


  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  async openFilters() {
    const filters = this.filters;
    const modal = await this.popoverController.create({
      component: FilterInvoicesPopoverComponent,
      cssClass: 'full-popover sm:w-min sm:rounded',
      componentProps: {
        filters
      },
      backdropDismiss: true
    });
    await modal.present();
    const {data} = await modal.onDidDismiss();
    if (data) {
      this.store.dispatch(changeInvoiceFilters({filter: data}));
    }
  }



  generateBags() {
    this.filterBags = [];
    if (this.filters.month.filter && this.filters.month.query !== '') {
      this.filterBags.push({
        name: 'month',
        bag: `Mes: ${this.filters.month.query}`
      });
    }
    if (this.filters.year.filter && this.filters.year.query !== '') {
      this.filterBags.push({
        name: 'year',
        bag: `Año: ${this.filters.year.query}`
      });
    }
    if (this.filters.period.filter && this.filters.period.query !== '') {
      this.filterBags.push({
        name: 'period',
        bag: `Periodo/Concepto: ${this.filters.period.query}`
      });
    }
  }
  deleteBag(i) {
    if (this.filterBags[i].name === 'year') {
      this.filters.year.filter = false;
    }
    if (this.filterBags[i].name === 'month') {
      this.filters.month.filter = false;
    }
    if (this.filterBags[i].name === 'period') {
      this.filters.period.filter = false;
    }
    this.filterBags.splice(i, 1);
    this.store.dispatch(changeInvoiceFilters({filter: this.filters}));
  }
}

