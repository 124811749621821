import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {SessionService} from '../../../shared/services/session.service';
import {AuthTokenModel} from '../../../shared/models/auth-token-model';
import {UserModel} from '../../../shared/models/user-model';
import { SharedService } from 'src/app/shared/services/shared.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private apiUrl = environment.api_url;
  authTokenModel: AuthTokenModel | undefined;
  user: UserModel | undefined;
  onUserChange: BehaviorSubject<UserModel>;

  constructor(private httpClient: HttpClient, private sessionService: SessionService, private sharedService: SharedService) {
    this.onUserChange = new BehaviorSubject(undefined);
  }

  me(): Promise<UserModel> {
    return new Promise<any>((resolve, reject) => {
      this.httpClient.get<UserModel>(`${this.apiUrl}me`).subscribe(res => {
        this.sharedService.getPermissions(res.id).subscribe(permisos => {
          this.sharedService.setPermisos(permisos);
        });
        
        this.sessionService.setUserInfo(res).then(_ => {
          this.user = res;
          this.onUserChange.next(this.user);
          resolve(res);
        }, reject);
      });
    });
  }
  login(email: string, password: string): Promise<AuthTokenModel> {
    const data = {
      email,
      password
    };
    return new Promise((resolve, reject) => {
      this.httpClient.post<AuthTokenModel>(`${this.apiUrl}login`, data)
        .subscribe((response: any) => {
          this.authTokenModel = response;
          this.sessionService.setTokens(response).then(_ => {
            this.me();
            resolve(response);
          });
        }, reject);
    });
  }
  forgotPass(email: string): Promise<AuthTokenModel> {
    const data = {
      email
    };
    return new Promise((resolve, reject) => {
      this.httpClient.post<AuthTokenModel>(`${this.apiUrl}users/forgotPassword`, data)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  resetPassword(token: string): Promise<UserModel> {
    return new Promise<any>((resolve, reject) => {
      this.httpClient.get<UserModel>(`${this.apiUrl}users/resetPassword/${token}`).subscribe(res => {
        resolve(res);
      }, reject);
    });
  }
  register(name: string, email: string, password: string, password_confirmation: string): Promise<AuthTokenModel> {
    const data = {
      name,
      email,
      password,
      password_confirmation
    };
    return new Promise((resolve, reject) => {
      this.httpClient.post<AuthTokenModel>(`${this.apiUrl}auth/register`, data)
        .subscribe((response: any) => {
          this.authTokenModel = response;
          this.sessionService.setTokens(response).then(_ => {
            this.me();
            resolve(response);
          });
        }, reject);
    });
  }

}
