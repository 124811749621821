import {createReducer, on} from "@ngrx/store";
import * as fromShared from './../actions/shared.actions';
import {Product} from "../../../modules/product/interfaces/product";

export const featureKey = 'shared';

export interface State {

  products: {
    data: Product[],
    status: 'loading' | 'error' | 'ready',
    error: any;
  };

}

export const initialState: State = {
  products: {
    data: [],
    status: 'ready',
    error: null
  },
}

export const reducer = createReducer(
  initialState,
  on(fromShared.loadProducts, (state, payload) => ({...state, products: { ...state.products, status: 'loading'}})),
  on(fromShared.loadProductsSuccess, (state, payload) => ({...state, products: { ...state.products, status: 'ready', data: payload.data}})),
  on(fromShared.loadProductsFailure, (state, payload) => ({...state, products: { ...state.products, status: 'error', error: payload.error}})),
);
