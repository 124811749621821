import {createAction, props} from "@ngrx/store";
import {Product} from "../../../modules/product/interfaces/product";
export const loadProducts = createAction(
  '[Shared] Load Products'
);

export const loadProductsSuccess = createAction(
  '[Shared] Load Products - Success',
  props<{ data: Product[] }>()
);

export const loadProductsFailure = createAction(
  '[Shared] Load Products - Error',
  props<{ error: any; }>()
);

