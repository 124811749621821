import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Periodicity, Product } from '../interfaces/product';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ClientFilters } from '../../client/interfaces/clientFilters';

@Injectable({
  providedIn: 'root'
})
export class ProductService implements Resolve<any>  {
  private apiUrl = environment.api_url;
  products: Product[];
  onProductsChange: BehaviorSubject<Product[]>;
  constructor(private httpClient: HttpClient) {
    this.onProductsChange = new BehaviorSubject<Product[]>(this.products);
  }
  /**
   * Resolver
   *
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([
        this.loadProducts()
      ]).then(
        () => {
          resolve(true);
        },
        reject
      );
    });
  }
  loadProducts(): Promise<Product[]> {
    return new Promise((resolve, reject) => {
      this.httpClient.get<Product[]>(`${this.apiUrl}products`).subscribe(res => {
        this.products = res;
        this.onProductsChange.next(this.products);
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  getProducts() {    
    return this.httpClient.get<Product[]>(`${this.apiUrl}products`);
  }

  getPeriodicity() {    
    return this.httpClient.get<Periodicity[]>(`${this.apiUrl}periodicities`);
  }

  addZipCode(product_id: number, periodicity_id: number, zipCode: number) {
    const data = {
      periodicity_id,
      zipCode
    }
    this.httpClient.post<any>(`${this.apiUrl}zipCode/${product_id}`, data);
  }

  filterProducts(filters: ClientFilters): Promise<Product[]> {
    return new Promise((resolve, reject) => {
      let params = '';
      if (filters.name.filter && filters.name.query !== '') {
        params += params === '' ? '?' : '&';
        params += `name=${filters.name.query}`;
      }
      this.httpClient.get<Product[]>(`${this.apiUrl}products${params}`).subscribe(res => {
        this.products = res;
        this.onProductsChange.next(this.products);
        resolve(res);
      }, error => {
        reject(error);
      });
    });

  }
}
