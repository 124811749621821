import {Directive, ElementRef} from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[tail-input]'
})
export class TailInputDirective {

  constructor(el: ElementRef) {
    el.nativeElement.className += ' mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm';
  }

}
