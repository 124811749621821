import {Component, Input, OnInit} from '@angular/core';
import {ClientFilters, Filter} from '../../../client/interfaces/clientFilters';
import {PopoverController} from '@ionic/angular';
import {SubscriptionFilters} from '../../interfaces/subscription-filters';
import {SharedService} from '../../../../shared/services/shared.service';
import {Periodicity, Product} from '../../../product/interfaces/product';

@Component({
  selector: 'app-filter-subscriptions-popover',
  templateUrl: './filter-subscriptions-popover.component.html',
  styleUrls: ['./filter-subscriptions-popover.component.scss'],
})
export class FilterSubscriptionsPopoverComponent implements OnInit {
  statusOptions = [
    'Solicitado', 'Programado', 'Instalado', 'Dado de Baja'
  ];
  productModel = '';
  @Input() filters: SubscriptionFilters = {
    client: {
      filter: false,
      query: ''
    },
    product: {
      filter: false,
      query: []
    },
    periodicity: {
      filter: false,
      query: ''
    },
    increase_date: {
      filter: false,
      query: ''
    },
    actual_price: {
      filter: false,
      query: ''
    },
    status: {
      filter: false,
      query: ''
    },
  };
  products: Product[] = [];
  periodicities: Periodicity[] = [];
  constructor(private popoverController: PopoverController, private sharedService: SharedService) { }

  ngOnInit() {
    this.sharedService.getProducts().subscribe(res => {
      this.products = res;
    });
    this.sharedService.getPeriodicities().subscribe(res => {
      this.periodicities = res;
    });
  }

  async save() {
    const popover = await this.popoverController.getTop();
    await popover.dismiss(this.filters);
  }
  async close() {
    const popover = await this.popoverController.getTop();
    await popover.dismiss();
  }

  onSelectIncreaseDate(event) {
    this.filters.increase_date.filter = event;

  }

  onProductSelect(event) {
    if (event) {
      if (!this.filters.product.query) {
        this.filters.product.query = [];
      }
      if (!this.filters.product.query.find(item => item === event)) {
        this.filters.product.query.push(event);
      }
      this.productModel = '';
    }
  }
  deleteProduct(i) {
    this.filters.product.query.splice(i, 1);
  }

}
