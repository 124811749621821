import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../app-state';
import * as fromReport from '../../../store/selectors/report.selectors';
import * as ReportActions from '../../../store/actions/report.actions';
import {Subject} from 'rxjs';
@Component({
  selector: 'app-report-step-one',
  templateUrl: './report-step-one.component.html',
  styleUrls: ['./report-step-one.component.css']
})
export class ReportStepOneComponent implements OnInit, OnDestroy {
  name = '';
  modules = [
    {
      key: 'clients',
      name: 'Clientes',
      description: 'Trabaja sobre clientes y todas sus propiedades'
    },
    {
      key: 'subscriptions',
      name: 'Suscripciones',
      description: 'Trabaja sobre suscripciones y todas sus propiedades'
    }
    ,
    {
      key: 'invoices',
      name: 'Facturación',
      description: 'Trabaja sobre facturación y todas las cuentas corrientes de los clientes'
    }
  ]
  moduleSelected = 0;
  private _unsubscribeAll: Subject<any>;
  constructor(private store: Store<AppState>) {
    this._unsubscribeAll = new Subject<any>();
    this.store.select(fromReport.selectReportModule).subscribe(module => {
      this.moduleSelected = this.modules.findIndex(item => item.key === module);
    });
    this.store.select(fromReport.selectReportName).subscribe(name => {
      this.name = JSON.parse(JSON.stringify(name));
    });
  }

  ngOnInit(): void {
  }

  onModuleChange(module_index): void {
    this.store.dispatch(ReportActions.changeModule({module: this.modules[module_index].key}));
  }
  onArrowUp() {
    this.store.dispatch(ReportActions.changeModule({module: this.modules[this.moduleSelected - 1 < 0 ? (this.modules.length - 1)  : this.moduleSelected - 1].key}));
  }
  onArrowDown() {
    this.store.dispatch(ReportActions.changeModule({module: this.modules[this.moduleSelected + 1 > (this.modules.length - 1)  ? 0 : this.moduleSelected + 1].key}));
  }
  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  onNameChange(event) {
    this.store.dispatch(ReportActions.changeReportName({name: event}));
  }

}
