<div class="bg-white rounded-md shadow">
    <div *ngIf="loading" class="text-center flex items-center justify-center mx-auto">
        <mat-spinner></mat-spinner>
    </div>
    <form [formGroup]="formGroup">
        <h1 class="flex items-center mx-5 sm:mx-10 justify-between text-xl font-semibold text-blue-900">Ventas por usuario
            <div class="filtros">
                <div class="form-group">
                    <strong>Estado</strong>
                    <select class="form-control text-base" formControlName="estado" name="estado">
                        <!-- Remove this if you want a continuous filter -->
                        <option value=""></option>
                        <option value="Solicitado">Solicitado</option>
                        <option value="Programado">Programado</option>
                        <option value="Instalado">Instalado</option>
                        <option value="Dado de Baja">Dado de Baja</option>
                    </select>
                </div>
                <div class="form-group">
                    <strong>Desde</strong>
                    <input type="date" formControlName="desde" name="desde" class="form-control appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent flex-1"
                    />
                </div>
                <div class="form-group">
                    <strong>Desde</strong>
                    <input type="date" formControlName="hasta" name="hasta" class="form-control appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent flex-1"
                    />
                </div>
                <div class="flex flex-1 form-group items-end">
                    <ion-button size="small" (click)="filtrar()" class="button button-small button-solid capitalize h-9 hydrated ion-activatable ion-focusable md my-0">
                        Filtrar
                        <ion-icon name="filter">
                        </ion-icon>
                    </ion-button>
                </div>
            </div>
        </h1>
        <table class="hidden sm:table min-w-full table-auto">
            <thead class="text-sm">
                <tr class="bg-gray-800">
                    <th class="py-2"><span class="text-gray-300">Usuario</span></th>
                    <th class="py-2"><span class="text-gray-300">Ventas</span></th>
                </tr>
            </thead>
            <tbody *ngIf="users?.length > 0 && !loading;else other_content" class="bg-gray-200">
                <tr class="bg-white border-4 border-gray-200 text-sm" *ngFor="let user of users">
                    <td class="text-center py-2">
                        <span class="text-center ml-2 font-semibold">{{user?.user?.name}}</span>
                    </td>
                    <td class="text-center py-2">
                        <span class="text-center ml-2 font-semibold">{{user?.total}}</span>
                    </td>
                </tr>
            </tbody>
            <ng-template #other_content>
                <tbody class="bg-gray-200">
                    <tr class="bg-white border-4 border-gray-200 text-sm">
                        <td colspan="2" class="text-center">
                            <span class="text-xl text-center ml-2 font-semibold">No hay datos</span>
                        </td>
                    </tr>
                </tbody>
            </ng-template>
        </table>
        <br>
        <h1 class="flex items-center mx-5 sm:mx-10 justify-between text-xl font-semibold text-blue-900">Suscripciones
        </h1>
        <table class="hidden sm:table min-w-full table-auto">
            <thead class="text-sm">
                <tr class="bg-gray-800">
                    <th class="py-2"><span class="text-gray-300">Cliente</span></th>
                    <th class="py-2"><span class="text-gray-300">Servicio</span></th>
                    <th class="py-2"><span class="text-gray-300">Fecha solicitud</span></th>
                    <th class="py-2"><span class="text-gray-300">Creado por</span></th>
                </tr>
            </thead>
            <tbody *ngIf="suscr?.length > 0 && !loading;else other_content2" class="bg-gray-200">
                <tr class="bg-white border-4 border-gray-200 text-sm" *ngFor="let sus of suscr">
                    <td class="text-center">
                        <span class="text-center ml-2">{{sus?.client?.name}}</span>
                    </td>
                    <td class="text-center py-2">
                        <span class="text-center ml-2">{{sus?.product?.name}}</span>
                    </td>
                    <td class="text-center py-2">
                        <span class="text-center ml-2">{{sus?.created_at | date: format}}</span>
                    </td>
                    <td class="text-center py-2">
                        <span class="text-center ml-2">{{sus?.user?.name}}</span>
                    </td>
                </tr>
            </tbody>
            <ng-template #other_content2>
                <tbody class="bg-gray-200">
                    <tr class="bg-white border-4 border-gray-200 text-sm">
                        <td colspan="4" class="text-center">
                            <span class="text-xl text-center ml-2 font-semibold">No hay datos</span>
                        </td>
                    </tr>
                </tbody>
            </ng-template>
        </table>
    </form>
</div>