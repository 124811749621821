<nav class="bg-gray-800">
  <div class="max-w-8xl mx-auto px-2 sm:px-4 lg:px-8">
    <div class="relative flex items-center justify-between h-16">
      <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
        <!-- Mobile menu button-->
        <button (click)="switchMenu()" type="button"
          class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
          aria-controls="mobile-menu" aria-expanded="false">
          <span class="sr-only">Open main menu</span>
          <svg [class.hidden]="showMenu" [class.block]="!showMenu" class="h-6 w-6" xmlns="http://www.w3.org/2000/svg"
            fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
          <svg [class.hidden]="!showMenu" [class.block]="showMenu" class="h-6 w-6" xmlns="http://www.w3.org/2000/svg"
            fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
        <div class="flex-shrink-0 flex items-center">
          <h2 class="hidden lg:block h-8 w-auto m-0 text-white font-medium text-xl">{{user ? user.company.name :
            'PWG'}} <ion-chip *ngIf="!environment.production" color="primary"
              tail-tooltip="Usted esta operando en entorno de desarrollo">Dev</ion-chip></h2>
          <h2 class="block sm:hidden h-8 w-auto m-0 text-white font-medium text-xl">{{user ? user.company.name :
            'PWG'}} <ion-chip *ngIf="!environment.production" color="primary"
              tail-tooltip="Usted esta operando en entorno de desarrollo">Dev</ion-chip></h2>
        </div>
        <div class="hidden sm:block">
          <div class="ml-5 flex items-baseline space-x-2">
            <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
            <div class="dropdown" *ngFor="let route of routes">
              <button [routerLink]="route.route" routerLinkActive="active"
                class="nav-link inline-block align-middle text-center">
                <ion-icon [name]="route.icon" class="mr-2"></ion-icon>
                <span>{{route.name}}</span>
              </button>
              <div class="dropdown-content">
                <a *ngFor="let childRoute of route.children" [routerLink]="childRoute.route" class="nav-link">
                  <span>{{childRoute.name}}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
        <div *ngIf="false" class="relative">
          <div>
            <button (click)="notificationsOpen = !notificationsOpen"
              class="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
              <span class="sr-only">View notifications</span>
              <!-- Heroicon name: outline/bell -->
              <svg id="notifications-icon" class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
              </svg>
            </button>
          </div>
          <div (blur)="closeNotifications($event)"
            class="origin-top-right {{notificationsOpen ? 'transition ease-out duration-100 opacity-0 scale-95 transform opacity-100 scale-100' : 'transition ease-in duration-75 transform opacity-0 scale-0'}} absolute right-0 mt-2 bg-white rounded-md shadow-lg overflow-hidden z-20"
            style="width:20rem;">
            <div class="py-2">
              <a href="#" class="flex items-center px-4 py-3 border-b hover:bg-gray-100 -mx-2">
                <img class="h-8 w-8 rounded-full object-cover mx-1"
                  src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80"
                  alt="avatar">
                <p class="text-gray-600 text-sm mx-2">
                  <span class="font-bold" href="#">Sara Salah</span> replied on the <span
                    class="font-bold text-blue-500" href="#">Upload Image</span> artical . 2m
                </p>
              </a>
              <a href="#" class="flex items-center px-4 py-3 border-b hover:bg-gray-100 -mx-2">
                <img class="h-8 w-8 rounded-full object-cover mx-1"
                  src="https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"
                  alt="avatar">
                <p class="text-gray-600 text-sm mx-2">
                  <span class="font-bold" href="#">Slick Net</span> start following you . 45m
                </p>
              </a>
              <a href="#" class="flex items-center px-4 py-3 border-b hover:bg-gray-100 -mx-2">
                <img class="h-8 w-8 rounded-full object-cover mx-1"
                  src="https://images.unsplash.com/photo-1450297350677-623de575f31c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80"
                  alt="avatar">
                <p class="text-gray-600 text-sm mx-2">
                  <span class="font-bold" href="#">Jane Doe</span> Like Your reply on <span
                    class="font-bold text-blue-500" href="#">Test with TDD</span> artical . 1h
                </p>
              </a>
              <a href="#" class="flex items-center px-4 py-3 hover:bg-gray-100 -mx-2">
                <img class="h-8 w-8 rounded-full object-cover mx-1"
                  src="https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=398&q=80"
                  alt="avatar">
                <p class="text-gray-600 text-sm mx-2">
                  <span class="font-bold" href="#">Abigail Bennett</span> start following you . 3h
                </p>
              </a>
            </div>
            <a href="#" class="block bg-gray-800 text-white text-center font-bold py-2">See all notifications</a>
          </div>
        </div>

        <!-- Profile dropdown -->
        <div class="ml-3 relative">
          <div>
            <button (click)="menuState = !menuState" type="button"
              class="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
              id="user-menu" aria-expanded="false" aria-haspopup="true">
              <span class="sr-only">Open user menu</span>
              <span id="user-menu-image"
                class="flex items-center flex-shrink-0 h-8 w-8 rounded-full justify-center bg-indigo-500 text-white font-semibold">
                {{user ? user.name[0] : 'O'}}
              </span>
            </button>
          </div>


          <div (blur)="closeUserMenu($event)"
            class="origin-top-right {{menuState ? 'transition ease-out duration-100 opacity-0 scale-95 transform opacity-100 scale-100' : 'transition ease-in duration-75 transform opacity-0 scale-0'}} absolute z-50 right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
            <a [routerLink]="'user/' + user?.id" (click)="menuState = !menuState"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full" role="menuitem">Perfil &
              Ajustes</a>
            <a routerLink="invoices/payments/bank" (click)="menuState = !menuState"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full" role="menuitem">Archivos de
              Banco</a>
            <a routerLink="logistic/companies" (click)="menuState = !menuState"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full" role="menuitem">Logística</a>
            <!--<a routerLink="reports/list" (click)="menuState = !menuState" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full" role="menuitem">Reportes</a>-->
            <button class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
              (click)="logoutUser()" role="menuitem">Cerrar Sesión</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Mobile menu, show/hide based on menu state. -->
  <div [@openClose]="showMenu ? 'open' : 'closed'" class="sm:hidden" id="mobile-menu">
    <div class="px-2 pt-2 pb-3 space-y-1">
      <ng-container *ngFor="let route of routes">
        <a class="mobile-link" [routerLink]="route.route" routerLinkActive="active">{{route.name}}</a>
        <div class="pl-6" *ngIf="isActive(route.route) && route.children">
          <a href="#" *ngFor="let child of route.children" class="mobile-link"
            [routerLink]="child.route">{{child.name}}</a>
        </div>
      </ng-container>
    </div>
  </div>
</nav>
<ng-container *ngFor="let route of routes">
  <div class="bg-gray-200 shadow-md" *ngIf="isActive(route.route) && route?.children">
    <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
      <div class="flex items-center justify-between h-12">
        <!-- Título del Route y los Children alineados horizontalmente -->
        <div class="flex items-center space-x-4">
          <!-- Ajusta la clase de margen según sea necesario para alinear con los children -->
          <h2 class="text-gray-700 font-bold text-lg m-0">{{ route.name }}</h2>

          <!-- Links de los Children -->
          <div class="flex items-baseline space-x-4">
            <a *ngFor="let child of route.children" [routerLink]="child.route" routerLinkActive="active"
              class="nav-link-white">{{ child.name }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>