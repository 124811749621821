import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportsPageComponent } from './pages/reports-page/reports-page.component';
import {RouterModule, Routes} from '@angular/router';
import {LogisticCompaniesPageComponent} from '../logistic/pages/logistic-companies-page/logistic-companies-page.component';
import {CreateLogisticCompanyPopoverComponent} from '../logistic/components/create-logistic-company-popover/create-logistic-company-popover.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import { CreateReportPageComponent } from './pages/create-report-page/create-report-page.component';
import {SharedModule} from '../../shared/shared.module';
import { ReportStepOneComponent } from './components/steps/report-step-one/report-step-one.component';
import { ReportStepTwoComponent } from './components/steps/report-step-two/report-step-two.component';
import { ReportStepTwoClientsComponent } from './components/steps/report-step-two-clients/report-step-two-clients.component';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import { StoreModule } from '@ngrx/store';
import * as fromReport from './store/reducers/report.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ReportEffects } from './store/effects/report.effects';
import {MatSortModule} from '@angular/material/sort';
import {BrowserModule} from '@angular/platform-browser';
import { ReportStepThreeComponent } from './components/steps/report-step-three/report-step-three.component';
import { ReportStepFourComponent } from './components/steps/report-step-four/report-step-four.component';
import { ReportStepTwoSubscriptionsComponent } from './components/steps/report-step-two-subscriptions/report-step-two-subscriptions.component';
import { ReportPreviewPopoverComponent } from './components/report-preview-popover/report-preview-popover.component';
import {MatDialogModule} from '@angular/material/dialog';
import { ReportStepTwoInvoicesComponent } from './components/steps/report-step-two-invoices/report-step-two-invoices.component';
import { MonthlyReportsPageComponent } from './pages/monthly-reports-page/monthly-reports-page.component';
import { TableFilterPipe } from './pages/monthly-reports-page/table-filter.component';
import { InvoicesInformPageComponent } from './pages/invoices-inform-page/invoices-inform-page.component';
import { TableInformFilterPipe } from './pages/monthly-reports-page/table-inform-filter.component';
import { SellsInformPageComponent } from './pages/sells-inform-page/sells-inform-page.component';
import { SubscriptionInformPageComponent } from './pages/subscription-inform-page/subscription-inform-page.component';
import { BajasInformPageComponent } from './pages/bajas-inform-page/bajas-inform-page.component';
import { ChangeUnsuscribeMotivePopoverComponent } from './pages/bajas-inform-page/change-unsuscribe-motive-popover/change-unsuscribe-motive-popover.component';

const routes: Routes = [
  { path: '',   redirectTo: 'monthly', pathMatch: 'full' },
  {
    path: 'list',
    component: ReportsPageComponent
  },
  {
    path: 'new',
    component: CreateReportPageComponent
  },
  {
    path: 'monthly',
    component: MonthlyReportsPageComponent
  },
  {
    path: 'invoices',
    component: InvoicesInformPageComponent
  },
  {
    path: 'sells',
    component: SellsInformPageComponent
  },
  {
    path: 'subscriptions',
    component: SubscriptionInformPageComponent
  },
  {
    path: 'bajas',
    component: BajasInformPageComponent
  }
];

@NgModule({
  declarations: [
    ReportsPageComponent,
    CreateReportPageComponent,
    ReportStepOneComponent,
    ReportStepTwoComponent,
    ReportStepTwoClientsComponent,
    ReportStepThreeComponent,
    ReportStepFourComponent,
    ReportStepTwoSubscriptionsComponent,
    ReportPreviewPopoverComponent,
    ReportStepTwoInvoicesComponent,
    MonthlyReportsPageComponent,
    TableFilterPipe,
    TableInformFilterPipe,
    InvoicesInformPageComponent,
    SellsInformPageComponent,
    SubscriptionInformPageComponent,
    BajasInformPageComponent,
    ChangeUnsuscribeMotivePopoverComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    SharedModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatDialogModule,
    StoreModule.forFeature(fromReport.reportFeatureKey, fromReport.reducer),
    EffectsModule.forFeature([ReportEffects])
  ],
  exports: [
    RouterModule
  ]
})
export class ReportsModule { }
