import { Component, OnInit } from '@angular/core';
import * as moment from "moment";
import { ReportService } from "../../services/report.service";
import { IMaxMinReport } from "../../interfaces/IMaxMinReport";
import { Subscription } from "../../../subscription/interfaces/subscription";
import { IMonthlyReport, IMonthlyReportResult } from "../../interfaces/IMonthlyReport";


@Component({
  selector: 'app-monthly-reports-page',
  templateUrl: './monthly-reports-page.component.html',
  styleUrls: ['./monthly-reports-page.component.css']
})
export class MonthlyReportsPageComponent implements OnInit {
  months = [
    {
      month: 'Enero',
      number: 1
    }
  ];
  selectedMonthNumber = moment().month() + 1;
  selectedMonth = {
    month: 'Enero',
    number: 1
  };
  years = [];
  monthly: IMonthlyReportResult = null;
  list:any[] = [];
  selectedYear = moment().year();
  maxMinReport: IMaxMinReport = {
    actual: {
      min: 0,
      max: 0,
      output: 0
    }
  };
  forma_pago: string;
  pago: string;
  problema_pago: string;
  constructor(private _service: ReportService) {

  }

  ngOnInit(): void {
    moment.locale('es', {
      week: {
        dow: 7
      }
    });
    moment.locale('es');
    this.loadMonths();
    this.loadYears();
    this.selectedMonth = {
      month: moment().format('MMMM'),
      number: this.selectedMonthNumber
    }
    this.searchMonthlyReports();
  }
  onSelectMonth() {
    this.selectedMonth = this.months.find(item => item.number === parseInt(this.selectedMonthNumber.toString(), null));
  }
  loadYears() {
    this.years = [];
    let now = moment().subtract(5, 'year');
    for (let i = 0; i < 10; i++) {
      this.years.push(now.year());
      now.add(1, 'year');
    }
  }
  loadMonths() {
    this.months = [];
    const months = moment.months();
    let i = 1;
    months.forEach(monthName => {
      this.months.push({
        month: monthName,
        number: i
      });
      i = i + 1;
    })
  }

  searchMonthlyReports() {
    this._service.getMaxMin(this.selectedYear, this.selectedMonthNumber).subscribe(res => {
      this.maxMinReport = res;
    });
    this._service.getMonthly(this.selectedYear, this.selectedMonthNumber).subscribe(res => {
      this.monthly = res;
      this.list = [];
      this.monthly.reports.forEach(r => {
        this.list.push({
          suscripcion: r.subscription.id,
          servicio: r.subscription.product.name + '(' + r.subscription.periodicity.name + ')',
          cliente: r.subscription.client.name,
          forma_pago: r.subscription.client_payment_method?.method,
          precio_mensual: r.subscription.periodic_price,
          cobro_mes: r.debt,
          ingreso_mes: r.payments,
          problema_pago: r.subscription.client.payment_problems
        });        
      });
    })
  }
  getIncrease(n1, n2) { // Actual, Anterior
    if (n2 === 0) {
      return (n1 * 100.0).toFixed(0);
    }
    return ((n1 - n2) / n2 * 100.0).toFixed(2);
  }
}
