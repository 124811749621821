<div class="dark:bg-gray-600 bg-blue-500 w-full shadow-lg">
    <div class="flex items-center mx-5 sm:mx-10 justify-between py-5 text-white">
        <div>
            <h2 class="my-0 text-lg font-bold">Mensuales de <span class="capitalize">{{selectedMonth?.month}},
          {{selectedYear}}</span></h2>
        </div>
        <div class="inline-block align-middle self-center hidden sm:block">
            <select id="person_type" [(ngModel)]="selectedMonthNumber" (ngModelChange)="onSelectMonth()" name="person_type" autocomplete="person_type" class="inline-block align-middle capitalize text-white bg-blue-600 opacity-90 hover:opacity-100 font-semibold py-2 pl-3 pr-2 rounded border border-blue-600 border-r-12 focus:outline-none">
        <option class="capitalize" *ngFor="let month of months" [value]="month.number">{{month.month}}</option>
      </select>
            <select id="year" [(ngModel)]="selectedYear" name="year" autocomplete="year" class="inline-block ml-1 align-middle capitalize text-white bg-blue-600 opacity-90 hover:opacity-100 font-semibold py-2 pl-3 pr-2 rounded border border-blue-600 border-r-12 focus:outline-none">
        <option class="capitalize" *ngFor="let year of years" [value]="year">{{year}}</option>
      </select>
            <button (click)="searchMonthlyReports()" class="ml-3 inline-block align-middle text-white bg-blue-600 opacity-90 hover:opacity-100 font-semibold text-sm py-2 px-3 rounded border border-blue-600">Buscar</button>
        </div>
    </div>
</div>
<div class="">
    <div class="w-full grid grid-cols-2 gap-2 mt-2">
        <div class="tail-container w-full col-span-2 mb-0">
            <h1 class="text-center text-xl font-semibold py-2 text-blue-900">Minimos y Máximos</h1>
            <div class="grid grid-cols-12 gap-x-6 p-4">
                <div class="col-span-6">
                    <div class="report-box zoom-in">
                        <div class="relative cursor-pointer rounded-md bg-gradient-to-br from-yellow-500 via-yellow-500 to-yellow-600 p-5 text-white">
                            <div class="flex">
                                <div class="text-xl">
                                    Problemas de pago<br>del mes
                                </div>
                            </div>
                            <div class="flex">
                                <div class="text-3xl font-semibold leading-8 mt-6">{{maxMinReport.actual.max}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-span-6">
                    <div class="report-box zoom-in">
                        <div class="relative cursor-pointer rounded-md bg-gradient-to-br from-blue-500 via-blue-400 to-green-400 p-5 text-white">
                            <div class="flex">
                                <div class="text-xl">
                                    Solucionados<br>del mes
                                </div>
                            </div>
                            <div class="flex">
                                <div class="text-3xl font-semibold leading-8 mt-6">{{maxMinReport.actual.min}}</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="tail-container  col-span-2">
            <form #form="ngForm">
                <h1 class="flex items-center mx-5 sm:mx-10 justify-between text-xl font-semibold text-blue-900">Mensualidades

                    <div class="filtros">
                        <div class="form-group">
                            <strong>Forma de pago</strong>
                            <select class="form-control" name="forma_pago" [(ngModel)]="forma_pago">
                <!-- Remove this if you want a continuous filter -->
                <option></option>
                <option value="Increase">Tarjeta de Crédito / CBU</option>
                <option value="Cheque">Cheque</option>
                <option value="Efectivo">Efectivo</option>
              </select>
                        </div>
                        <div class="form-group">
                            <strong>Pagó?</strong>
                            <select class="form-control" name="pago" [(ngModel)]="pago">
                <!-- Remove this if you want a continuous filter -->
                <option></option>
                <option value="si">Si</option>
                <option value="no">No</option>
              </select>
                        </div>
                        <div class="form-group">
                            <strong>Problema de pago?</strong>
                            <select class="form-control" name="problema_pago" [(ngModel)]="problema_pago">
                <!-- Remove this if you want a continuous filter -->
                <option></option>
                <option value="si">Si</option>
                <option value="no">No</option>
              </select>
                        </div>
                    </div>
                </h1>

                <div class="bg-gray-600">
                    <div class="flex mx-5 justify-between py-3 text-white">
                        <div class="flex items-center">
                            <h2 class="my-0 text-lg font-bold">Importe cobrado por mensualidades: ${{monthly?.totalPayments | number:'1.2-2'}}</h2>
                        </div>
                        <div class="flex items-center">
                            <h2 class="my-0 text-lg font-bold">Importe a cobrar por mensualidades: ${{monthly?.totalDebt | number:'1.2-2'}}</h2>
                        </div>
                    </div>
                </div>
                <table class="hidden sm:table min-w-full table-auto" *ngIf="monthly">
                    <thead class="text-xs">
                        <tr class="bg-gray-800 text-center">
                            <th class="py-2"><span class="text-gray-300">Suscripción</span></th>
                            <th class="py-2"><span class="text-gray-300">Servicio</span></th>
                            <th class="py-2"><span class="text-gray-300">Cliente</span></th>
                            <th class="py-2"><span class="text-gray-300">Forma de pago</span></th>
                            <th class="py-2"><span class="text-gray-300">Precio Mensual</span></th>
                            <th class="py-2"><span class="text-gray-300">Cobro del mes</span></th>
                            <th class="py-2"><span class="text-gray-300">Ingreso mensual</span></th>
                            <th class="py-2"><span class="text-gray-300">Problema de pago</span></th>
                        </tr>
                    </thead>
                    <tbody class="bg-gray-200">
                        <tr class="bg-white border-4 border-gray-200 text-xs" *ngFor="let month of list | tableFilter: form.value">
                            <td class="text-center">
                                <span class="text-center ml-2">{{month.suscripcion}}</span>
                            </td>
                            <td class="text-center">
                                <span class="text-center ml-2">{{month.servicio}}</span>
                            </td>
                            <td class="text-center">
                                <span class="text-center ml-2">{{month.cliente}}</span>
                            </td>
                            <td class="text-center">
                                <span class="text-center ml-2">{{month.forma_pago}}</span>
                            </td>

                            <td class="text-center">
                                <span class="text-center ml-2">$ {{month.precio_mensual}}</span>
                            </td>
                            <td class="text-center">
                                <span class="text-center ml-2">$ {{month.cobro_mes}}</span>
                            </td>
                            <td class="text-center">
                                <span class="text-center ml-2">$ {{month.ingreso_mes}}</span>
                            </td>
                            <td class="text-center">
                                <span class="text-center ml-2">{{month?.problema_pago == 1 ? 'Si'
                  : 'No'}}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>

    </div>
</div>