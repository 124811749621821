import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {DarkModeService} from './shared/services/dark-mode.service';
import {takeUntil} from 'rxjs/operators';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy{

  mode = 'light';
  private subject: Subject<any>;

  constructor(private darkModeService: DarkModeService) {
    this.subject = new Subject();
  }
  ngOnInit(): void {
    this.darkModeService.onModeChange.pipe(takeUntil(this.subject))
        .subscribe(mode => {
          if (mode === 'dark') {
            document.querySelector('html')?.classList.add('dark');
          } else {
            document.querySelector('html')?.classList.remove('dark');
          }
        });
    this.darkModeService.getMode().then(res => {
      this.mode = res;
    });
  }

  ngOnDestroy(): void {
    this.subject.next();
    this.subject.complete();
  }

}
