<div>
  <div class="mt-1 relative">
    <button type="button" (click)="open = !open" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label" class="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:text-gray-300 dark:bg-gray-700 dark:border-gray-800"
    [class.py-4]="material">
      <span class="flex items-center" *ngIf="selected">
        <div class="flex items-center flex-shrink-0 h-6 w-6 rounded-full justify-center bg-gray-200">
          {{selected.name[0]}}
        </div>
        <span class="ml-3 block truncate">
          {{selected.name}}
        </span>
      </span>
      <span *ngIf="!selected" class="flex items-center">
        Seleccione un Cliente
      </span>
      <span class="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
        <!-- Heroicon name: solid/selector -->
        <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
        </svg>
      </span>
    </button>

    <div class="absolute mt-1 w-full rounded-md bg-white shadow-lg z-50 dark:text-gray-300 dark:bg-gray-700 dark:border-gray-800" *ngIf="open">
      <div class="relative">
        <input
          type="text"
          [(ngModel)]="query"
          (ngModelChange)="onSearch()"
          class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 py-3 sm:text-sm border-gray-300 rounded-md"
          placeholder="Buscar..">

        <div class="absolute top-0 right-0 px-3 py-2">
          <svg class="w-6 h-6 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
        </div>
      </div>
      <ul tabindex="-1" role="listbox" aria-labelledby="listbox-label"  class="max-h-28 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
        <ng-container *ngFor="let client of clients | SearchClient:query">
          <app-client-selector-item [client]="client" [selected]="selected?.id === client.id" (click)="selectClient(client)"></app-client-selector-item>
        </ng-container>

      </ul>
    </div>
  </div>
</div>
