import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../app-state';
import * as fromReport from '../../../store/selectors/report.selectors';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';


@Component({
  selector: 'app-report-step-two',
  templateUrl: './report-step-two.component.html',
  styleUrls: ['./report-step-two.component.css']
})
export class ReportStepTwoComponent implements OnInit, OnDestroy {

  module = 'clients';
  private _unsubscribeAll: Subject<any>;
  constructor(private store: Store<AppState>) {
    this._unsubscribeAll = new Subject<any>();
  }

  ngOnInit(): void {
    this.store.select(fromReport.selectReportModule).pipe(takeUntil(this._unsubscribeAll)).subscribe(module => {
      this.module = module;
    })
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
