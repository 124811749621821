<div class="w-full bg-gray-600 grid grid-cols-7 items-center">
  <div class="col-span-6 text-white">
    <p *ngIf="!filters" class="text-left px-5 py-2 text-white">No se aplicaron filtros</p>
    <div *ngIf="filters" class="text-left px-5">
      <ion-chip *ngFor="let bag of filterBags; let i = index" color="secondary">
        <ion-label>{{bag.bag}}</ion-label>
        <ion-icon name="close" (click)="deleteBag(i)"></ion-icon>
      </ion-chip>
    </div>
  </div>
  <div class="col-span-1">
    <ion-button (click)="openFilters()" size="small" class="capitalize">Filtrar <ion-icon name="filter" class="ml-3 text-sm"></ion-icon></ion-button>
  </div>

</div>
<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
  <!-- Name Column -->
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
  </ng-container>
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
    <td mat-cell *matCellDef="let element"> {{element.client.name}} </td>
  </ng-container>
  <ng-container matColumnDef="product">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Producto </th>
    <td mat-cell *matCellDef="let element"> {{element.product.name}} </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
