import { Injectable } from '@angular/core';
import {Actions, concatLatestFrom, createEffect, ofType} from '@ngrx/effects';

import {catchError, concatMap, exhaustMap, map} from 'rxjs/operators';
import { Observable, EMPTY } from 'rxjs';

import * as ReportActions from '../actions/report.actions';
import {ClientService} from '../../../client/services/client.service';
import * as fromReports from '../selectors/report.selectors';
import {Store} from '@ngrx/store';
import {State} from '../reducers/report.reducer';
import {ReportService} from '../../services/report.service';
import {SubscriptionService} from '../../../subscription/services/subscription.service';
import {ToastController} from '@ionic/angular';
import {PaymentService} from '../../../invoice/services/payment.service';

@Injectable()
export class ReportEffects {


  changeStep$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReportActions.changeStep),
      concatLatestFrom(_ => this.store.select(fromReports.selectReportModule)),
      concatMap(([action, module]) => {
        if (action.step === 1 && module === 'clients') {
          this.store.dispatch(ReportActions.loadClients());
        }
        if (action.step === 1 && module === 'subscriptions') {
          this.store.dispatch(ReportActions.loadSubscriptions());
        }
        if (action.step === 1 && module === 'invoices') {
          this.store.dispatch(ReportActions.loadInvoices());
        }

        if (action.step === 2) {
          this.store.dispatch(ReportActions.loadTableData());
        }
        return EMPTY as Observable<{ type: string }>;
      })
    );
  });

  loadClients$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReportActions.loadClients),
      concatMap(() => this.clientService.getClients()
        .pipe(
          map(clients => (ReportActions.loadClientSuccess({ clients }))),
          catchError(() => EMPTY)
        )
      )
    );
  });
  loadInvoices$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReportActions.loadInvoices),
      concatLatestFrom(action => this.store.select(fromReports.selectInvoiceFilters)),
      concatMap(([action, filters]) => this.invoiceService.getInvoices(filters)
        .pipe(
          map(invoices => (ReportActions.loadInvoicesSuccess({ invoices }))),
          catchError(() => EMPTY)
        )
      )
    );
  });

  loadTableData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReportActions.loadTableData),
      concatLatestFrom(action => this.store.select(fromReports.selectReport)),
      concatMap(([action, report]) => this.reportService.getTableData(report)
        .pipe(
          map(data => (ReportActions.loadTableDataSuccess({ data }))),
          catchError(() => EMPTY)
        )
      )
    );
  });

  changeClientFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReportActions.changeClientFilters),
      concatMap((state) => this.clientService.filterClients(state.filter)
        .pipe(
          map(clients => (ReportActions.loadClientSuccess({ clients }))),
          catchError(() => EMPTY)
        )
      )
    );
  });
  changeInvoiceFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReportActions.changeInvoiceFilters),
      concatMap((state) => this.invoiceService.getInvoices(state.filter)
        .pipe(
          map(invoices => (ReportActions.loadInvoicesSuccess({ invoices }))),
          catchError(() => EMPTY)
        )
      )
    );
  });
  changeSubscriptionFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReportActions.changeSubscriptionFilters),
      concatMap((state) => this.subscriptionService.filterSubscriptions(state.filter)
        .pipe(
          map(subscriptions => (ReportActions.loadSubscriptionsSuccess({ subscriptions }))),
          catchError(() => EMPTY)
        )
      )
    );
  });

  loadSubscriptions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReportActions.loadSubscriptions),
      concatLatestFrom(action => this.store.select(fromReports.selectSubscriptionFilters)),
      concatMap(([action, filters]) => this.subscriptionService.filterSubscriptions(filters)
        .pipe(
          map(subscriptions => (ReportActions.loadSubscriptionsSuccess({ subscriptions }))),
          catchError(() => EMPTY)
        )
      )
    );
  });
  saveReport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReportActions.saveReport),
      concatLatestFrom(action => this.store.select(fromReports.selectReport)),
      concatMap(([action, report]) => this.reportService.storeReport(report)
        .pipe(
          map(_ => {
            this.presentToast('El reporte se guardó correctamente');
            return (ReportActions.reloadReports());
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });
  loadReports$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReportActions.loadReports),
      concatMap((state) => this.reportService.getReports()
        .pipe(
          map(reports => (ReportActions.loadReportsSuccess({reports}))),
          catchError(() => EMPTY)
        )
      )
    );
  });

  constructor(private actions$: Actions, private clientService: ClientService, private subscriptionService: SubscriptionService, private reportService: ReportService, private store: Store<State>, private toastController: ToastController, private invoiceService: PaymentService) {}
  async presentToast(message) {
    const toast = await this.toastController.create({
      message,
      duration: 2000
    });
    await toast.present();
  }
}
