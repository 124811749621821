<nav aria-label="Progress" class="mt-2">
  <ol class="border border-gray-300 rounded-md divide-y divide-gray-300 flex md:divide-y-0 bg-white max-h-14">

    <li class="relative flex-1 flex" *ngFor="let option of steps; let i = index">

      <!-- Completed Step -->
      <button (click)="onStepChange(i)" class="group flex items-center w-full">
              <span class="px-6 py-4 flex items-center text-sm font-medium">
                <span *ngIf="step <= i" class="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-indigo-600 rounded-full" aria-current="step">
                  <span class="text-indigo-600">{{('0' + (i + 1)).slice(-2)}}</span>
                </span>
                <span *ngIf="step > i" class="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800">
                  <svg class="w-6 h-6 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                </span>
                <span class="ml-4 text-sm font-medium text-gray-900">{{option}}</span>
              </span>
      </button>

      <div class="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
        <svg class="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
          <path d="M0 -2L20 40L0 82" vector-effect="non-scaling-stroke" stroke="currentcolor" stroke-linejoin="round"></path>
        </svg>
      </div>
    </li>

  </ol>
</nav>
