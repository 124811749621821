import {Directive, ElementRef, HostListener, Input, Renderer2} from '@angular/core';

@Directive({
  selector: '[tail-tooltip]',
})
export class TooltipDirective {

  tooltip: HTMLElement;
  @Input("tail-tooltip") tooltipTitle: string;
  delay = 500;
  @HostListener("mouseover") onMouseEnter() {
    this.showTooltip();
  }

  @HostListener("mouseleave") onMouseLeave() {
    this.tooltip.remove();
  }

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  showTooltip() {
    this.tooltip = this.renderer.createElement("span");
    this.tooltip.appendChild(this.renderer.createElement("span"));
    this.renderer.appendChild(
      this.tooltip,
      this.renderer.createText(this.tooltipTitle)
    );
    const hostPos = this.el.nativeElement.getBoundingClientRect();
    let top, left;

    top = hostPos.bottom;
    left = hostPos.left + hostPos.width / 2;
    this.renderer.setStyle(this.tooltip, "top", `${top}px`);
    this.renderer.setStyle(this.tooltip, "left", `${left / 4}px`);
    this.renderer.appendChild(document.body, this.tooltip);
    this.renderer.addClass(this.tooltip, "tooltip");
  }
}
