<div class="tail-container">
    <div class="dark:bg-gray-600 bg-blue-500 w-full shadow-lg">
      <div class="flex mx-5 sm:mx-10 justify-between py-5 text-white">
        <div>
          <h2 class="my-0 text-lg font-bold">Reportes</h2>
          <span class="text-sm font-semibold text-blue-300" *ngIf="dataSource.data">Se encontro {{dataSource.data.length}} report{{dataSource.data.length > 1 ? 'e':'es'}} en la base de datos</span>
        </div>
        <div class="self-center hidden sm:block">
          <a routerLink="/reports/new" class="text-white bg-blue-600 opacity-90 hover:opacity-100 font-semibold py-3 px-3 rounded">Nuevo Reporte</a>
        </div>
      </div>
    </div>
</div>
<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 w-full">
  <!-- Name Column -->
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
  </ng-container>
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
  </ng-container>
  <ng-container matColumnDef="created_at">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de Creación </th>
    <td mat-cell *matCellDef="let element"> {{element.created_at | date: 'd/M/y'}} </td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef> Acciones </th>
    <td mat-cell *matCellDef="let element"> <button (click)="openReportPreview(element)" class="bg-blue-500 text-white px-3 py-1.5 border rounded-md text-sm hover:bg-blue-600">Ver</button> </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
