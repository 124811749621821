import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'SearchClient'
})
export class SearchClientPipe implements PipeTransform {

  transform(arr: any[], name: string): any[] {
    if (!name || name?.length === 0) {
      return arr;
    }
    return arr.filter(item => item.name.toLowerCase().startsWith(name.toLowerCase()) || item.email.toLowerCase().startsWith(name.toLowerCase())  || item.document.toLowerCase().startsWith(name.toLowerCase())  || item.address.toLowerCase().startsWith(name.toLowerCase())  || item.phone.toLowerCase().startsWith(name.toLowerCase()));
  }

}
