import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import * as ReportActions from '../../store/actions/report.actions';
import * as fromReport from '../../store/selectors/report.selectors';
import {AppState} from '../../../../app-state';

@Component({
  selector: 'app-create-report-page',
  templateUrl: './create-report-page.component.html',
  styleUrls: ['./create-report-page.component.css']
})
export class CreateReportPageComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;
  step = 0;
  steps = [
    'Módulo',
    'Filtros',
    'Campos',
    'Resultado'
  ]
  formData = {
    module: 'clients',
  }
  constructor(private store: Store<AppState>) {
    this.store.dispatch(ReportActions.reloadReports());
    this._unsubscribeAll = new Subject<any>();
    this.store.select(fromReport.selectReportStep).pipe(takeUntil(this._unsubscribeAll)).subscribe(newStep => {
      this.step = newStep;
    });
    this.store.select(fromReport.selectReport).pipe(takeUntil(this._unsubscribeAll)).subscribe(formData => {
      this.formData = formData;
    });
  }

  ngOnInit(): void {
  }

  setStep(step: number) {
    this.store.dispatch(ReportActions.changeStep({step}));
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  saveReport() {
    this.store.dispatch(ReportActions.saveReport());
  }
}
