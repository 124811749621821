import { Injectable } from '@angular/core';
import {StorageMap} from '@ngx-pwa/local-storage';
import {BehaviorSubject} from 'rxjs';
import {AuthTokenModel} from '../models/auth-token-model';

@Injectable({
  providedIn: 'root'
})
export class DarkModeService {
  private readonly MODE = 'MODE';
  mode: string | undefined = 'light';
  onModeChange: BehaviorSubject<any>;

  constructor(private storage: StorageMap) {
    this.onModeChange = new BehaviorSubject({});
  }

  async setMode(mode: string): Promise<boolean> {
    return await new Promise((resolve, reject) => {
      try {
        this.storage.set(this.MODE, mode, {type: 'string'}).subscribe((data) => {
          this.mode = mode;
          if (mode === 'dark') {
            document.querySelector('html')?.classList.add('dark');
          } else {
            document.querySelector('html')?.classList.remove('dark');
          }
          this.onModeChange.next(mode);
          resolve(data);
        });
      } catch (err) {
        reject(err);
      }
    });
  }
  async getMode(): Promise<string> {
    return await new Promise((resolve, reject) => {
      try {
        this.storage.get(this.MODE, {type: 'string'}).subscribe((data) => {
          this.mode = data;
          this.onModeChange.next(data);
          resolve(data);
        });
      } catch (err) {
        reject(err);
      }
    });
  }
}
