<div *ngIf="loading" class="loading">
    <span class="loader"></span>
</div>
<div class="bg-white rounded-md shadow">
    <form [formGroup]="formGroup">
        <h1 class="flex items-center mx-5 sm:mx-10 justify-between text-xl font-semibold text-blue-900 py-4">Bajas
            <div class="filtros flex-wrap items-end justify-around">
                <div class="form-group">
                    <strong>Producto</strong>
                    <select class="form-control text-base" formControlName="products" name="products">
                        <option value=""></option>
                        <option *ngFor="let option of products" [value]="option.id">{{option?.name}}</option>
                    </select>
                </div>
                <div class="form-group">
                    <strong>Periodicidad</strong>
                    <select class="form-control text-base" formControlName="periodicity_id" name="periodicity_id">
                        <option value=""></option>
                        <option *ngFor="let option of periodicitys" [value]="option.id">{{option?.name}}</option>
                    </select>
                </div>
                <div class="form-group">
                    <strong>Desde</strong>
                    <input type="date" formControlName="from" name="from" class="form-control appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent flex-1"
                    />
                </div>
                <div class="form-group">
                    <strong>Hasta</strong>
                    <input type="date" formControlName="to" name="to" class="form-control appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent flex-1"
                    />
                </div>
                <div class="flex flex-1 form-group items-end">
                    <ion-button size="small" (click)="filtrar()" class="button button-small button-solid capitalize h-9 hydrated ion-activatable ion-focusable md my-0">
                        Filtrar
                        <ion-icon name="filter">
                        </ion-icon>
                    </ion-button>
                    <ion-button size="small" (click)="downloadFile()" class="button button-small button-solid capitalize h-9 hydrated ion-activatable ion-focusable md my-0">
                        Descargar
                        <ion-icon name="cloud-download-outline">
                        </ion-icon>
                    </ion-button>
                </div>
            </div>
        </h1>
        <table class="hidden sm:table min-w-full table-auto">
            <thead class="text-sm">
                <tr class="bg-gray-800">
                    <th class="py-2"><span class="text-gray-300">Id Suscripción</span></th>
                    <th class="py-2"><span class="text-gray-300">Servicio</span></th>
                    <th class="py-2"><span class="text-gray-300">Estado</span></th>
                    <th class="py-2"><span class="text-gray-300">Cliente</span></th>
                    <th class="py-2"><span class="text-gray-300">Forma de pago</span></th>
                    <th class="py-2"><span class="text-gray-300">Dirección</span></th>
                    <th class="py-2"><span class="text-gray-300">Piso</span></th>
                    <th class="py-2"><span class="text-gray-300">Número</span></th>
                    <th class="py-2"><span class="text-gray-300">Localidad</span></th>
                    <th class="py-2"><span class="text-gray-300">Fecha Solicitud</span></th>
                    <th class="py-2"><span class="text-gray-300">Fecha Instalación</span></th>
                    <th class="py-2"><span class="text-gray-300">Precio Único</span></th>
                    <th class="py-2"><span class="text-gray-300">Precio Mensual</span></th>
                    <th class="py-2"><span class="text-gray-300">Veraz</span></th>
                    <th class="py-2"><span class="text-gray-300">Motivo</span></th>
                </tr>
            </thead>
            <tbody *ngIf="suscr?.length > 0 && !loading;else other_content2" class="bg-gray-200">
                <tr class="bg-white border-4 border-gray-200 text-sm" *ngFor="let sus of suscr">
                    <td class="text-center">
                        <span class="text-center ml-2">{{sus?.id}}</span>
                    </td>
                    <td class="text-center py-2">
                        <span class="text-center ml-2">{{sus?.service}}</span>
                    </td>
                    <td class="text-center py-2">
                        <span class="text-center ml-2">{{sus?.status}}</span>
                    </td>
                    <td class="text-center py-2">
                        <span class="text-center ml-2">{{sus?.client?.name}}</span>
                    </td>
                    <td class="text-center py-2">
                        <span class="text-center ml-2">{{sus?.payment_method}}</span>
                    </td>
                    <td class="text-center py-2">
                        <span class="text-center ml-2">{{sus?.address?.address}}</span>
                    </td>
                    <td class="text-center py-2">
                        <span class="text-center ml-2">{{sus?.address?.floor}}</span>
                    </td>
                    <td class="text-center py-2">
                        <span class="text-center ml-2">{{sus?.address?.apartment}}</span>
                    </td>
                    <td class="text-center py-2">
                        <span class="text-center ml-2">{{sus?.address?.city}}</span>
                    </td>
                    <td class="text-center py-2">
                        <span class="text-center ml-2">{{sus?.original_date | date: format}}</span>
                    </td>
                    <td class="text-center py-2">
                        <span class="text-center ml-2">{{sus?.installation_date | date: format}}</span>
                    </td>
                    <td class="text-center py-2">
                        <span class="text-center ml-2">{{sus?.install_price}}</span>
                    </td>
                    <td class="text-center py-2">
                        <span class="text-center ml-2">{{sus?.periodic_price}}</span>
                    </td>
                    <td class="text-center py-2">
                        <span class="text-center ml-2">{{sus?.veraz_score}}</span>
                    </td>
                    <td class="text-center py-2">
                        <button (click)="presentUnsuscribeMotivePopover(sus)" class="bg-blue-500 text-white px-3 py-1.5 border rounded-md text-sm hover:bg-blue-600">Cambiar
                            motivo</button>
                    </td>
                </tr>
            </tbody>
            <ng-template #other_content2>
                <tbody class="bg-gray-200">
                    <tr class="bg-white border-4 border-gray-200 text-sm">
                        <td colspan="15" class="text-center">
                            <h1 class="text-xl text-center ml-2 font-semibold">No hay datos</h1>
                        </td>
                    </tr>
                </tbody>
            </ng-template>
        </table>
    </form>
</div>