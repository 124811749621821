import { Action, createReducer, on } from '@ngrx/store';
import * as ReportActions from '../actions/report.actions';
import {Report} from '../../interfaces/report';
import {Client} from '../../../client/interfaces/client';
import {ClientFilters} from '../../../client/interfaces/clientFilters';
import {Subscription} from '../../../subscription/interfaces/subscription';
import {Payment} from '../../../invoice/interfaces/payment';
import {Invoice} from '../../../client/interfaces/invoice';

export const reportFeatureKey = 'reports';

export interface State {
  report: Report,
  step: number;
  clients?: Client[],
  invoices?: Invoice[],
  reports: Report[],
  subscriptions?: Subscription[],
  data: any[]
}

export const initialState: State = {
  report: {
    module: 'clients',
    clientFilters: {
      name: {
        filter: false,
        query: ''
      },
      address: {
        filter: false,
        query: ''
      },
      postalCode: {
        filter: false,
        query: ''
      },
      phone: {
        filter: false,
        query: ''
      },
      user: {
        filter: false,
        query: ''
      },
      email: {
        filter: false,
        query: ''
      },
      payment_problems: {
        filter: false,
        query: ''
      },
      payment_problems_min_months: {
        filter: false,
        query: ''
      },
      payment_problems_max_months: {
        filter: false,
        query: ''
      },
      with_referrers: {
        filter: false,
        query: ''
      },
      referred_by: {
        filter: false,
        query: ''
      },
      leads: {
        filter: false,
        query: ''
      },
      document: {
        filter: false,
        query: ''
      }
    },
    subscriptionFilters: {
      client: {
        filter: false,
        query: ''
      },
      product: {
        filter: false,
        query: []
      },
      periodicity: {
        filter: false,
        query: ''
      },
      increase_date: {
        filter: false,
        query: ''
      },
      actual_price: {
        filter: false,
        query: ''
      },
      status: {
        filter: false,
        query: ''
      }
    },
    invoiceFilters: {
      month: {
        filter: false,
        query: ''
      },
      year: {
        filter: false,
        query: ''
      },
      period: {
        filter: false,
        query: ''
      }
    },
    name: '',
    tableData: {
      cols: [],
      vals: [],
      rows: [],
      aggregatorName: 'Cuenta',
      renderName: 'Table',
      rendererName: 'Table'
    }
  },
  step: 0,
  clients: [],
  subscriptions: [],
  invoices: [],
  reports: null,
  data: []
};


export const reducer = createReducer(
  initialState,

  on(ReportActions.loadReports, state => state),
  on(ReportActions.loadReportsSuccess, (state, payload) => ({...state, reports: payload.reports})),
  on(ReportActions.loadTableDataSuccess, (state, payload) => ({...state, data: payload.data})),
  on(ReportActions.reloadReports, state => ({...state, report: initialState.report, step: initialState.step, clients: initialState.clients})),
  on(ReportActions.changeStep, (state, payload) => ({... state, step: payload.step})),
  on(ReportActions.changeTableConfig, (state, payload) => ({... state, report: {...state.report, tableData: payload.tableData}})),
  on(ReportActions.changeClientFilters, (state, payload) => ({... state, report: {...state.report, clientFilters: payload.filter}})),
  on(ReportActions.changeInvoiceFilters, (state, payload) => ({... state, report: {...state.report, invoiceFilters: payload.filter}})),
  on(ReportActions.changeSubscriptionFilters, (state, payload) => ({... state, report: {...state.report, subscriptionFilters: payload.filter}})),
  on(ReportActions.changeModule, (state, payload) => ({... state, report: {...state.report, module: payload.module}})),
  on(ReportActions.changeReportName, (state, payload) => ({... state, report: {...state.report, name: payload.name}})),
  on(ReportActions.loadClientSuccess, (state, payload) => ({...state, clients: payload.clients})),
  on(ReportActions.loadInvoicesSuccess, (state, payload) => ({...state, invoices: payload.invoices})),
  on(ReportActions.loadSubscriptionsSuccess, (state, payload) => ({...state, subscriptions: payload.subscriptions}))

);

