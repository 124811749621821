import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Client } from 'src/app/modules/client/interfaces/client';

@Component({
  selector: 'app-client-selector',
  templateUrl: './client-selector.component.html',
  styleUrls: ['./client-selector.component.scss'],
})
export class ClientSelectorComponent implements OnInit {

  open = false;
  @Input() material = false;
  @Input() clients: Client[] = [];
  @Input() selected: Client;
  @Output() clientSelected: EventEmitter<any> = new EventEmitter<any>();
  query = '';
  constructor() { }

  ngOnInit() {
    
  }

  onSearch() {

  }
  selectClient(client) {
    console.log(client);
    this.open = false;
    if (this.selected && this.selected.id === client.id) {
      this.selected = undefined;
      this.clientSelected.emit(undefined);
    }
    else {
      this.selected = client;
      this.clientSelected.emit(client);
    }

  }

}
