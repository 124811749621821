import { Injectable } from '@angular/core';
import {
    CanActivate,
    Router
} from '@angular/router';
import {from, Observable, of} from 'rxjs';
import {SessionService} from '../services/session.service';
import {switchMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AnonGuard implements CanActivate {
    constructor(private sessionService: SessionService, private router: Router) { }
    canActivate(): Observable<boolean> | boolean {
        return from(this.sessionService.getToken()).pipe(
            switchMap(res => {
                if (res === null || res === undefined) {
                    return of(true);
                }
                if (res.length !== 0) {
                    this.router.navigate(['/home']);
                    return of(false);
                }
                else {
                    // this.router.navigate(['/home']);
                    return of(true);
                }
            }));
    }

}


