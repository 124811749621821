import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';
import { AnonGuard } from './shared/guards/anon.guard';
import { NavbarLayoutComponent } from './shared/layouts/navbar-layout/navbar-layout.component';
import { CreateDocumentPopoverComponent } from './modules/subscription/components/create-document-popover/create-document-popover.component';
import { StopDebitTableComponent } from './modules/subscription/components/stop-debit-table/stop-debit-table.component';

const routes: Routes = [
  {
    path: '',
    component: NavbarLayoutComponent,
    children: [
      { path: '', redirectTo: '/home', pathMatch: 'full' },
      {
        path: 'home',
        loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'clients',
        loadChildren: () => import('./modules/client/client.module').then(m => m.ClientModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'programming',
        loadChildren: () => import('./modules/programming/programming.module').then(
            m => m.ProgrammingModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'invoices',
        loadChildren: () => import('./modules/invoice/invoice.module').then(m => m.InvoiceModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'logistic',
        loadChildren: () => import('./modules/logistic/logistic.module').then(
            m => m.LogisticModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'units',
        loadChildren: () => import('./modules/units/unit.module').then(m => m.UnitModule)
      },
      {
        path: 'products/discount',
        loadChildren: () => import('./modules/discount/discount.module').then(
            m => m.DiscountModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'increases',
        loadChildren: () => import('./modules/increases/increases.module').then(
            m => m.IncreasesModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'products',
        loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'user',
        loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'maintenances',
        loadChildren: () => import('./modules/maintenance/maintenance.module').then(
            m => m.MaintenanceModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'reports',
        loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'mails',
        loadChildren: () => import('./modules/mail/mail.module').then(m => m.MailModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'subscriptions',
        loadChildren: () => import('./modules/subscription/subscription.module').then(
            m => m.SubscriptionModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'users',
        loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'clients/details/:id',
        component: CreateDocumentPopoverComponent,
      },
      {
        path: 'stop-debit',
        component: StopDebitTableComponent
      },
    ],
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
    canActivate: [AnonGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
