import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpHeaders,HttpResponse } from '@angular/common/http';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { NavController } from '@ionic/angular';
import { Subscription } from '../../interfaces/subscription';
import { SubscriptionChanges } from '../../interfaces/subscription-changes';
import { Discount } from '../../../discount/interfaces/discount';
import { Mail } from '../../../mail/interfaces/communication';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionDetailPageService implements Resolve<any> {
  private apiUrl = environment.api_url;
  subscription: Subscription;
  onSubscriptionChange: BehaviorSubject<Subscription>;
  discounts: Discount[];
  onDiscountsChange: BehaviorSubject<Discount[]>;
  routeParams: any;

  constructor(
    private httpClient: HttpClient,
    private navController: NavController
  ) {
    this.onSubscriptionChange = new BehaviorSubject<Subscription>(null);
    this.onDiscountsChange = new BehaviorSubject<Discount[]>(this.discounts);
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    this.routeParams = route.params;
    return new Promise((resolve, reject) => {
      Promise.all([this.loadSubscription(), this.loadDiscounts()]).then(() => {
        resolve(true);
      }, reject);
    });
  }

  getMailBySubscription(id) {
    return this.httpClient.get<Mail[]>(
      `${this.apiUrl}mailsBySubscription/${id}`
    );
  }
  loadSubscription() {
    return new Promise((resolve, reject) => {
      if (this.routeParams.id === 'new') {
        this.subscription = {
          periodic_price: 0,
          install_price: 0,
          status: 'Solicitado',
          client_payment_method_id: null,
          address_id: null,
          client_id: null,
          periodicity_id: null,
          product_id: null,
          client_payment_method: {
            name: '',
            description: '',
            holder: '',
            card_number: '',
            cbu: '',
            expires_in: '',
          },
          discounts: [],
        };
        this.onSubscriptionChange.next(this.subscription);
        resolve(this.subscription);
      }
      if (this.routeParams.id && this.routeParams.id !== 'new') {
        this.getSubscription(this.routeParams.id).then(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
            this.navController.back();
          }
        );
      }
    });
  }
  loadDiscounts(): Promise<Discount[]> {
    return new Promise((resolve, reject) => {
      this.httpClient.get<Discount[]>(`${this.apiUrl}discounts`).subscribe(
        (res) => {
          this.discounts = res;
          this.onDiscountsChange.next(this.discounts);
          resolve(res);
        },
        (error) => {
          reject(error.error);
        }
      );
    });
  }
  getSubscription(id): Promise<Subscription> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get<Subscription>(`${this.apiUrl}subscription/${id}`)
        .subscribe((res) => {
          this.subscription = res;
          this.onSubscriptionChange.next(this.subscription);
          resolve(res);
        }, reject);
    });
  }
  getSubscriptionChanges(id): Promise<SubscriptionChanges[]> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get<any>(`${this.apiUrl}changes/subscription/${id}`)
        .subscribe((items) => {
          const changeList = [];
          items.forEach((res) => {
            const changes: SubscriptionChanges = {
              id: res.id,
              user_id: res.user_id,
              user: res.user,
              model: res.model,
              action: res.action,
              data: JSON.parse(res.data),
              created_at: res.created_at,
              updated_at: res.updated_at,
            };
            changeList.push(changes);
          });
          resolve(changeList);
        }, reject);
    });
  }
  storeOrEdit(subscription) {
    if (this.routeParams.id === 'new') {
      return this.storeSubscription(subscription);
    } else {
      return this.editSubscription(subscription);
    }
  }
  verifyScore(subscription): Promise<any> {
    const data = {
      client_name: subscription.client.name,
      client_document: subscription.client.document,
      client_gender: subscription.client.gender,
      product_type_id: subscription.product.product_type_id,
    };

    if (subscription.client.client_id) {
      data['client_id'] = subscription.client.client_id;
    }

    return new Promise((resolve, reject) => {
      this.httpClient
        .post<any>(`${this.apiUrl}subscription/verify`, data)
        .subscribe((res) => {
          resolve(res);
        }, reject);
    });
  }
  completeIncrease(client): Promise<any> {
    // const data = {
    //   client_name: subscription.client.name,
    //   client_document: subscription.client.document,
    //   client_gender : subscription.client.gender,
    //   product_type_id: subscription.product.product_type_id
    // };
    return new Promise((resolve, reject) => {
      this.httpClient
        .get<any>(`${this.apiUrl}client/${client}/paymentMethod/link/`)
        .subscribe((res) => {
          resolve(res);
        }, reject);
    });
  }
  storeSubscription(subscription): Promise<Subscription> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post<Subscription>(`${this.apiUrl}subscription`, subscription)
        .subscribe((res) => {
          this.subscription = res;
          this.onSubscriptionChange.next(this.subscription);
          resolve(res);
        }, reject);
    });
  }
  editSubscription(subscription): Promise<Subscription> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .put<Subscription>(
          `${this.apiUrl}subscription/${subscription.id}`,
          subscription
        )
        .subscribe((res) => {
          this.subscription = res;
          this.onSubscriptionChange.next(this.subscription);
          resolve(res);
        }, reject);
    });
  }
  editSubscriptionUnsuscribe(subscription): Promise<Subscription> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post<Subscription>(
          `${this.apiUrl}subscription/editUnsuscribe/${subscription.id}`,
          subscription
        )
        .subscribe((res) => {
          this.subscription = res;
          this.onSubscriptionChange.next(this.subscription);
          resolve(res);
        }, reject);
    });
  }
  getFiles(subscription) {
    return this.httpClient.get<any[]>(
      `${this.apiUrl}subscription/files/${subscription.id}`
    );
  }
  getAttachedTypes() {
    return this.httpClient.get<any[]>(
      `${this.apiUrl}subscription/getAttachedTypes`
    );
  }
  getDocuments(subscription) {
    return this.httpClient.get<any[]>(
      `${this.apiUrl}subscription/getDocuments/${subscription.id}`
    );
  }
  downloadPDF(id) {
    return this.httpClient.get(`${this.apiUrl}subscription/downloadPDF/${id}`, {
      responseType: 'blob',
    });
  }
  sendDocument(susbcription_document_id, email) {
    var data = {
      email,
    };
    return this.httpClient.post<any[]>(
      `${this.apiUrl}subscription/sendMailDocument/${susbcription_document_id}`,
      data
    );
  }

  uploadFiles(formData: FormData) {
    return this.httpClient.post<any>(
      `${this.apiUrl}subscription/uploadFiles`,
      formData
    );
  }

  /* uploadFiles(subscription, data) {
    return this.httpClient.post<any[]>(
      `${this.apiUrl}subscription/uploadFiles/${subscription.id}`,
      { files: data }
    );
  } */
  updateDocument(subscription, data) {
    return this.httpClient.put<any[]>(
      `${this.apiUrl}subscription/updateDocument/${data.id}`,
      data
    );
  }
  storeDocument(subscription, data) {
    return this.httpClient.post<any[]>(
      `${this.apiUrl}subscription/storeDocument`,
      data
    );
  }

  getPlanilla(subscription) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    return this.httpClient.get<Blob>(
      `${this.apiUrl}subscription/planillaInstalacion/${subscription.id}`,
      httpOptions
    );
  }

  sendPlanilla(subscription, email) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.apiUrl}subscription/sendMailPlanilla`;
    const body = { id: subscription.id, mail: email };

    return this.httpClient.post(url, body, { headers });
  }

  downloadDocument(idDocument: string): Observable<HttpResponse<Blob>> {
    return this.httpClient.get(
      `${this.apiUrl}subscription/downloadDocument/${idDocument}`,
      {
        observe: 'response',
        responseType: 'blob',
      }
    );
  }

  deleteDocument(idDocument: string) {
    return this.httpClient.delete(
      `${this.apiUrl}subscription/deleteDocument/${idDocument}`
    );
  }

  updateAttachedFiles(formData: FormData) {
    formData.append('_method', 'PUT');
    return this.httpClient.post<any>(
      `${this.apiUrl}subscription/updateAttachedFiles/${formData.get('subscription_id')}`,
      formData
    );
  }
}
