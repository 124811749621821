
<div class="modal-header w-full">
  <p>Reporte </p>
  <button class="close-modal" mat-dialog-close><img src="assets/icon/close-modal.svg" class="w-6 h-6" alt="Cerrar Modal"></button>
</div>
<div mat-dialog-content>
  <div id="pivotTable" class="w-full"></div>
  <p class="text-red-500" *ngIf="error">{{error}}</p>
</div>
<div class="modal-footer w-full">
  <button mat-dialog-close class="px-4 py-2 text-white font-semibold bg-blue-500 rounded">
    Cerrar
  </button>
</div>
