import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Report} from '../interfaces/report';
import {IMaxMinReport} from "../interfaces/IMaxMinReport";
import {Subscription} from "../../subscription/interfaces/subscription";
import {IMonthlyReport, IMonthlyReportResult} from "../interfaces/IMonthlyReport";

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private apiUrl = environment.api_url;
  constructor(private httpClient: HttpClient) { }

  storeReport(report: Report) {
    return this.httpClient.post<Report>(`${this.apiUrl}reports`, report);
  }
  getReports() {
    return this.httpClient.get<Report[]>(`${this.apiUrl}reports`);
  }
  getTableData(report: Report) {
    return this.httpClient.post<any[]>(`${this.apiUrl}reports/tableData`, report);
  }
  getMaxMin(year, month) {
    return this.httpClient.get<IMaxMinReport>(`${this.apiUrl}reports/maxMin?mode=month&year=${year}&month=${month}`);
  }
  getMonthly(year, month) {
    return this.httpClient.get<IMonthlyReportResult>(`${this.apiUrl}reports/monthly?year=${year}&month=${month}`);
  }
}
