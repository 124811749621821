import { Component, Input, OnInit } from '@angular/core';
import { NavController, PopoverController, ToastController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Subscription } from 'src/app/modules/subscription/interfaces/subscription';
import { SubscriptionDetailPageService } from 'src/app/modules/subscription/pages/subscription-detail-page/subscription-detail-page.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { PopoverMain } from '../../../../../shared/classes/popover-main';

@Component({
  selector: 'app-change-unsuscribe-motive-popover',
  templateUrl: './change-unsuscribe-motive-popover.component.html',
  styleUrls: ['./change-unsuscribe-motive-popover.component.css']
})
export class ChangeUnsuscribeMotivePopoverComponent extends PopoverMain implements OnInit {

  @Input() subscription: Subscription;
  destroy$: Subject<any> = new Subject<any>();
  unsubscribeMotives: any[] = [];
  loading: boolean = false;
  constructor(
    private _sharedService: SharedService,
    popoverController: PopoverController,
    private toastController: ToastController,
    private router: NavController,
    private subscriptionService: SubscriptionDetailPageService,
  ) {
    super(popoverController);
  }

  ngOnInit() {
    this.subscription.unsubscribe_motive_id = this.subscription['motive'].id;

    this._sharedService.getUnsubscribeMotives().pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.unsubscribeMotives = res;
    });
  }
  cambiarMotivo() {
    this.loading = true;
    this.subscriptionService.editSubscriptionUnsuscribe(this.subscription).then(_ => {
      this.presentToast('El motivo de baja fue modificado correctamente');
      this.closePopover();
    }).catch(err => {
      console.log(err);
    });
  }
  async presentToast(message) {
    const toast = await this.toastController.create({
      message,
      duration: 2000
    });
    await toast.present();
  }
}
