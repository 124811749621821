import { Component, OnInit } from '@angular/core';
import {UserModel} from '../../models/user-model';
import * as navigation from '../../../config/navigation';
import {AuthService} from '../../../modules/auth/services/auth.service';
import {SessionService} from '../../services/session.service';
import {Router} from '@angular/router';
@Component({
  selector: 'app-navbar-layout',
  templateUrl: './navbar-layout.component.html',
  styleUrls: ['./navbar-layout.component.scss'],
})
export class NavbarLayoutComponent implements OnInit {

  user: UserModel | undefined;
  routes = navigation.routes;

  constructor(
    private sessionService: SessionService,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.authService.me().then(user => {
      this.user = user;
    }, err => {
      this.sessionService.getUserInfo().then(res => {
        if (res) {
          this.user = res;
        }
      });
    });

  }
  async logout() {
    this.sessionService.removeToken().then(res => {
      this.router.navigateByUrl('/auth/login');
    });
  }
}
