import {Component, Input, OnInit} from '@angular/core';
import {ClientFilters} from '../../../client/interfaces/clientFilters';
import {PopoverController} from '@ionic/angular';
import {InvoiceFilters} from '../../interfaces/payment';

@Component({
  selector: 'app-filter-invoices-popover',
  templateUrl: './filter-invoices-popover.component.html',
  styleUrls: ['./filter-invoices-popover.component.css']
})
export class FilterInvoicesPopoverComponent implements OnInit {

  @Input() filters: InvoiceFilters = {
    month: {
      filter: false,
      query: ''
    },
    year: {
      filter: false,
      query: ''
    },
    period: {
      filter: false,
      query: ''
    }
  };
  constructor(private popoverController: PopoverController) { }

  ngOnInit() {
    console.log(this.filters);
  }

  async save() {
    const popover = await this.popoverController.getTop();
    await popover.dismiss(this.filters);
  }
  async close() {
    const popover = await this.popoverController.getTop();
    await popover.dismiss();
  }

}
