import {Component, OnDestroy, OnInit} from '@angular/core';
import * as fromReport from '../../../store/selectors/report.selectors';
import {Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../app-state';
import {takeUntil} from 'rxjs/operators';
import * as moduleValues from '../../../../../config/values';
import * as moduleColumns from '../../../../../config/columns';
import * as ReportActions from '../../../store/actions/report.actions';
import {TableData} from '../../../interfaces/report';

declare var $: any;

@Component({
  selector: 'app-report-step-three',
  templateUrl: './report-step-three.component.html',
  styleUrls: ['./report-step-three.component.css']
})
export class ReportStepThreeComponent implements OnInit, OnDestroy {

  tableData: TableData;
  private _unsubscribeAll: Subject<any>;
  constructor(private store: Store<AppState>) {
    this._unsubscribeAll = new Subject<any>();
  }

  ngOnInit(): void {



    this.store.select(fromReport.selectTableConfig).pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
      this.tableData = data;
    });

    this.store.select(fromReport.selectTableData).pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
      var derivers = $.pivotUtilities.derivers;
      var renderers = $.extend($.pivotUtilities.renderers,
        $.pivotUtilities.plotly_renderers);
      $("#pivotTable").pivotUI(
        data,
        {
          renderers: renderers,
          rows: this.tableData.rows,
          cols: this.tableData.cols,
          vals: this.tableData.vals,
          renderName: this.tableData.renderName,
          aggregatorName: this.tableData.aggregatorName,
          rendererName: this.tableData.rendererName,
          onRefresh: (config) => this.onPivotTableRefresh(config)
        },
        false, "es"
      );
    });
  }
  onPivotTableRefresh(data) {
    this.store.dispatch(ReportActions.changeTableConfig({
      tableData: {
        rows: data.rows,
        cols: data.cols,
        vals: data.vals,
        renderName: data.renderName,
        aggregatorName: data.aggregatorName,
        rendererName: data.rendererName
      }
    }))
  }
  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
