import { Injectable } from '@angular/core';
import {JSONSchema, StorageMap} from '@ngx-pwa/local-storage';
import {AuthTokenModel} from '../models/auth-token-model';
import {UserModel} from '../models/user-model';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  schema: JSONSchema = {
    type: 'object',
    properties: {
      id: {
        type: 'number'
      },
      name: {
        type: 'string'
      },
      last_name: {
        type: 'string'
      },
      email: {
        type: 'string',
      },
      active_key: {
        type: 'string',
      },
      superuser: {
        type: 'number',
      },
      status: {
        type: 'number',
      },
      last_visit_at: {
        type: 'string',
      },
      notification_token: {
        type: 'string',
      },
      email_verified_at: {
        type: 'string',
      },
      created_at: {
        type: 'string',
      },
      updated_at: {
        type: 'string',
      },
      company_id: {
        type: 'number',
      },
      company: {
        type: 'object',
        properties: {
          id: {
            type: 'number'
          },
          name: {
            type: 'string'
          },
          email: {
            type: 'string',
          },
          created_at: {
            type: 'string',
          },
          updated_at: {
            type: 'string',
          }
        }
      }
    },
  };
  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly USER_INFO = 'USER_INFO';

  constructor(private storage: StorageMap) { }

  async setTokens(tokens: AuthTokenModel): Promise<boolean> {
    return await new Promise((resolve, reject) => {
      try {
        this.storage.set(this.JWT_TOKEN, tokens.access_token).subscribe((data) => {
          resolve(data);
        });
      } catch (err) {
        reject(err);
      }
    });
  }
  async getToken(): Promise<string> {
    return await new Promise((resolve, reject) => {
      try {
        this.storage.get(this.JWT_TOKEN, {type: 'string'}).subscribe((data) => {
          resolve(data);
        });
      } catch (err) {
        reject(err);
      }
    });
  }
  async removeToken(): Promise<boolean> {
    return await new Promise((resolve, reject) => {
      try {
        this.storage.delete(this.JWT_TOKEN).subscribe((data) => {
          resolve(data);
        });
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }
  async setUserInfo(user: UserModel): Promise<boolean> {
    return await new Promise((resolve, reject) => {
      try {
        this.storage.set(this.USER_INFO, JSON.stringify(user), undefined).subscribe((data) => {
          resolve(data);
        });
      } catch (err) {
        reject(err);
      }
    });
  }
  async getUserInfo(): Promise<UserModel> {
    return await new Promise((resolve, reject) => {
      try {
        this.storage.get<any>(this.USER_INFO, undefined).subscribe((data) => {
          resolve(data);
        });
      } catch (err) {
        reject(err);
      }
    });
  }
  async removeUserInfo(): Promise<any> {
    await this.storage.delete(this.USER_INFO);
  }
}
