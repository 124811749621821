import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "tableFilter"
})
export class TableFilterPipe implements PipeTransform {

    // transform(list: any[], value: string) {

    //     // If there's a value passed (male or female) it will filter the list otherwise it will return the original unfiltered list. 
    //     if (value) {

    //         if (value == 'no') {
    //             return list.filter(item => item.payments > 0);
    //         }
    //         if (value == 'si') {
    //             return list.filter(item => item.payments <= 0);
    //         }
    //     } else {
    //         return list
    //     }

    // }

    transform(list: any[], filters: Object) {
        const keys = Object.keys(filters).filter(key => filters[key]);
        // const filterUser = user => keys.every(key => user[key] == filters[key]);
        var listFilt = list.map(x=>x);

        keys.forEach(k => {
            switch (k) {
                case 'problema_pago':
                    if (filters[k] == 'no') {
                        listFilt = listFilt.filter(item => item[k] == 0);
                    }
                    if (filters[k] == 'si') {
                        listFilt = listFilt.filter(item => item[k] == 1);
                    }
                    break;
                case 'pago':
                    if (filters[k] == 'si') {
                        listFilt = listFilt.filter(item => item['ingreso_mes'] > 0);
                    }
                    if (filters[k] == 'no') {
                        listFilt = listFilt.filter(item => item['ingreso_mes'] <= 0);
                    }
                    break;
                default:
                    listFilt = listFilt.filter(item => item[k] == filters[k])
                    break;
            }
        });

        return keys.length ? listFilt : list;
    }
}