import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Client} from '../../modules/client/interfaces/client';
import {Periodicity, Product} from '../../modules/product/interfaces/product';
import {Discount} from "../../modules/discount/interfaces/discount";
import {Communication} from "../../modules/mail/interfaces/communication";
import { StorageMap } from '@ngx-pwa/local-storage';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private readonly PERMISOS = 'PERMISOS';
  private apiUrl = environment.api_url;
  constructor(private httpClient: HttpClient,private storage: StorageMap) { }

  
  async setPermisos(permisos: any): Promise<boolean> {
    return await new Promise((resolve, reject) => {
      try {
        this.storage.set(this.PERMISOS, JSON.stringify(permisos), undefined).subscribe((data) => {
          resolve(data);
        });
      } catch (err) {
        reject(err);
      }
    });
  }
  async getPermisos(): Promise<any> {
    return await new Promise((resolve, reject) => {
      try {
        this.storage.get(this.PERMISOS, undefined).subscribe((data) => {
          resolve(data);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  getClients() {
    return this.httpClient.get<Client[]>(`${this.apiUrl}client`);
  }
  getUnsubscribeMotives() {
    return this.httpClient.get<Client[]>(`${this.apiUrl}unsubscribeMotive`);
  }
  getMetWays() {
    return this.httpClient.get<any[]>(`${this.apiUrl}metWays`);
  }
  getTags() {
    return this.httpClient.get<any[]>(`${this.apiUrl}tags`);
  }
  getProducts() {
    return this.httpClient.get<Product[]>(`${this.apiUrl}products`);
  }
  getPeriodicities() {
    return this.httpClient.get<Periodicity[]>(`${this.apiUrl}periodicities`);
  }
  getDiscounts() {
    return this.httpClient.get<Discount[]>(`${this.apiUrl}discounts`);
  }
  getTemplates() {
    return this.httpClient.get<Communication[]>(`${this.apiUrl}communications/templates`);
  }

  sendMails(clients, template) {
    const data = {
      clients,
      template
    };
    return this.httpClient.post<any>(`${this.apiUrl}communications/sendMails`, data);
  }
  getPermissions(id) {
    return this.httpClient.get<any>(`${this.apiUrl}users/${id}/permissions`);
  }
}
