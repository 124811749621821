import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../app-state';
import {ReportService} from '../../services/report.service';
import {Report} from '../../interfaces/report';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
declare var $: any;

@Component({
  selector: 'app-report-preview-popover',
  templateUrl: './report-preview-popover.component.html',
  styleUrls: ['./report-preview-popover.component.css']
})
export class ReportPreviewPopoverComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;
  data: any[];
  constructor(private store: Store<AppState>, private reportService: ReportService, @Inject(MAT_DIALOG_DATA) public report: Report) {
    this._unsubscribeAll = new Subject<any>();
  }
  error = null;

  ngOnInit(): void {
    this.error = null;
    this.reportService.getTableData(this.report).subscribe(res => {
      this.data = res;
      var derivers = $.pivotUtilities.derivers;
      var renderers = $.extend($.pivotUtilities.renderers,
        $.pivotUtilities.plotly_renderers);
      $("#pivotTable").pivotUI(
        this.data,
        {
          renderers: renderers,
          rows: this.report.tableData.rows,
          cols: this.report.tableData.cols,
          vals: this.report.tableData.vals,
          aggregatorName: this.report.tableData.aggregatorName,
          rendererName: this.report.tableData.rendererName,
          showUI: false
        },
        false, "es"
      );
    });
  }

  ngOnDestroy(): void {
    console.log('destroy');
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
