<ion-header>
  <ion-toolbar color="primary">
    <ion-title>{{
      document.id ? "Editar Documento #" + document.id : "Nuevo Documento"
    }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<div class="shadow overflow-hidden sm:rounded-md" style="width: 632px">
  <div class="px-4 py-5 bg-white sm:p-6">
    <div class="grid grid-cols-6 gap-6">
      <div class="col-span-6 sm:col-span-3">
        <label
          for="attached_type"
          class="block text-sm font-medium text-gray-700"
          >Tipo de adjunto</label
        >
        <select
          id="attached_type"
          name="attached_type"
          [(ngModel)]="document.attached_id"
          autocomplete="attached_type"
          class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        >
          <option *ngFor="let type of attachedTypes" [value]="type.id">
            {{ type.name }}
          </option>
        </select>
      </div>
      <div class="col-span-6 sm:col-span-3">
        <label for="article" class="block text-sm font-medium text-gray-700"
          >Articulo</label
        >
        <div class="mt-1 relative rounded-md shadow-sm">
          <input
            type="text"
            name="article"
            [(ngModel)]="document.article"
            id="article"
            class="pl-5 pr-7"
            tail-input
          />
        </div>
      </div>
      <div class="col-span-6 sm:col-span-3">
        <label
          for="equipment_serial_number"
          class="block text-sm font-medium text-gray-700"
          >Nro. Serie Equipo</label
        >
        <div class="mt-1 relative rounded-md shadow-sm">
          <input
            type="text"
            name="equipment_serial_number"
            [(ngModel)]="document.equipment_serial_number"
            id="equipment_serial_number"
            class="pl-5 pr-7"
            tail-input
          />
        </div>
      </div>
      <div class="col-span-6 sm:col-span-3">
        <label
          for="filter_serial_number"
          class="block text-sm font-medium text-gray-700"
          >Nro. Serie Filtro</label
        >
        <div class="mt-1 relative rounded-md shadow-sm">
          <input
            type="text"
            name="filter_serial_number"
            [(ngModel)]="document.filter_serial_number"
            id="filter_serial_number"
            class="pl-5 pr-7"
            tail-input
          />
        </div>
      </div>
      <div class="col-span-6 sm:col-span-3">
        <label for="date" class="block text-sm font-medium text-gray-700"
          >Fecha</label
        >
        <div class="mt-1 relative rounded-md shadow-sm">
          <input
            [(ngModel)]="document.date"
            min="0"
            tail-input
            type="date"
            placeholder="0"
          />
        </div>
      </div>
      <div class="col-span-6 sm:col-span-3">
        <label for="hour" class="block text-sm font-medium text-gray-700"
          >Horario</label
        >
        <div class="mt-1 relative rounded-md shadow-sm">
          <input
            [(ngModel)]="document.hour"
            tail-input
          />
        </div>
      </div>
      <div class="col-span-6 sm:col-span-3">
        <label for="observation" class="block text-sm font-medium text-gray-700"
          >Observaciones</label
        >
        <div class="mt-1 relative rounded-md shadow-sm">
          <input
            type="area-text"
            name="observation"
            [(ngModel)]="document.observation"
            id="observation"
            class="pl-5 pr-7"
            tail-input
          />
        </div>
      </div>
      <div class="col-span-6 sm:col-span-3">
        <label for="phone" class="block text-sm font-medium text-gray-700"
          >Teléfono</label
        >
        <div class="mt-1 relative rounded-md shadow-sm">
          <input
            type="text"
            name="phone"
            [(ngModel)]="document.phone"
            id="phone"
            class="pl-5 pr-7"
            tail-input
          />
        </div>
      </div>
    </div>
  </div>
  <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
    <button
      (click)="sendForm()"
      class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    >
      Guardar
    </button>
  </div>
</div>
