import {Component, OnDestroy, OnInit} from '@angular/core';
import * as fromReport from '../../../store/selectors/report.selectors';
import {Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../app-state';
import {takeUntil} from 'rxjs/operators';
import {ReportService} from '../../../services/report.service';

declare var $: any;

@Component({
  selector: 'app-report-step-four',
  templateUrl: './report-step-four.component.html',
  styleUrls: ['./report-step-four.component.css']
})
export class ReportStepFourComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;
  data: any[];
  whiteSpaces = [];
  constructor(private store: Store<AppState>, private reportService: ReportService) {
    this._unsubscribeAll = new Subject<any>();
  }
  error = null;

  ngOnInit(): void {
    this.store.select(fromReport.selectReport).pipe(takeUntil(this._unsubscribeAll)).subscribe(report => {
      this.error = null;
      if (report.name) {
        this.reportService.getTableData(report).subscribe(res => {
          this.data = res;
          var derivers = $.pivotUtilities.derivers;
          var renderers = $.extend($.pivotUtilities.renderers,
            $.pivotUtilities.plotly_renderers);
          $("#pivotTable").pivotUI(
            this.data,
            {
              renderers: renderers,
              rows: report.tableData.rows,
              cols: report.tableData.cols,
              vals: report.tableData.vals,
              rendererName: report.tableData.rendererName,
              aggregatorName: report.tableData.aggregatorName,
              showUI: false
            },
            false, "es"
          );
        });
      }
      else {
        this.error = 'Para generar el reporte es necesario un nombre (Etapa 1)';
      }

    })
  }

  ngOnDestroy(): void {
    console.log('destroy');
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
