<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-button  (click)="close()">Cancelar</ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button slot="end" (click)="save()">Aplicar</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<div class="px-4 py-5 bg-white sm:p-6 w-96">
  <div class="grid grid-cols-6 gap-3">
    <div class="col-span-6">
      <label for="month" class="block text-sm font-semibold text-gray-700"><ion-checkbox [(ngModel)]="filters.month.filter" color="primary" class="align-middle mr-2"></ion-checkbox> <span class="align-middle">Filtrar por Mes</span></label>
      <select *ngIf="filters.month.filter" name="month" id="month" [(ngModel)]="filters.month.query" autocomplete="month" tail-input>
        <option value="Actual">Actual</option>
        <option value="Anterior">Anterior</option>
      </select>
    </div>
    <div class="col-span-6">
      <label for="year" class="block text-sm font-semibold text-gray-700"><ion-checkbox [(ngModel)]="filters.year.filter" color="primary" class="align-middle mr-2"></ion-checkbox> <span class="align-middle">Filtrar por Año</span></label>
      <select *ngIf="filters.year.filter" name="year" id="year" [(ngModel)]="filters.year.query" autocomplete="year" tail-input>
        <option value="Actual">Actual</option>
        <option value="Anterior">Anterior</option>
      </select>
    </div>
    <div class="col-span-6">
      <label for="period" class="block text-sm font-semibold text-gray-700"><ion-checkbox [(ngModel)]="filters.period.filter" color="primary" class="align-middle mr-2"></ion-checkbox> <span class="align-middle">Filtrar por Periodo/Concepto</span></label>
      <input *ngIf="filters.period.filter" type="text" name="period" id="period" placeholder="Periodo/Concepto" [(ngModel)]="filters.period.query" autocomplete="period" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
    </div>

  </div>
</div>
