<div class="dark:bg-gray-600 bg-blue-500 w-full shadow-lg">
  <div class="flex mx-5 sm:mx-10 justify-between py-5 text-white relative">
    <div>
      <h2 class="my-0 text-lg font-bold">Generar Reporte</h2>
    </div>
  </div>
</div>
<app-wizard-step [steps]="steps"></app-wizard-step>
<div class="bg-gray-100 py-12 px-4 flex justify-center sm:px-6 lg:py-16 lg:px-8">
  <div class="w-full">
    <div class="w-full max-w-3xl mx-auto">
      <h2 class="text-xl text-gray-500 mb-4">{{steps[step]}}</h2>
    </div>
    <ng-container [ngSwitch]="step">
      <app-report-step-one *ngSwitchCase="0"></app-report-step-one>
      <app-report-step-two *ngSwitchCase="1"></app-report-step-two>
      <app-report-step-three *ngSwitchCase="2"></app-report-step-three>
      <app-report-step-four *ngSwitchCase="3"></app-report-step-four>
    </ng-container>
    <div class="w-full max-w-3xl mx-auto">
      <div class="flex justify-items-end float-right mt-4" *ngIf="step < steps.length - 1">
        <button (click)="setStep(step + 1)" type="button" class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Siguiente
        </button>
      </div>
      <div class="flex justify-items-end float-right mt-4" *ngIf="step === steps.length - 1">
        <button (click)="saveReport()" type="button" class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Guardar
        </button>
      </div>
    </div>
  </div>

</div>
