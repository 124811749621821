import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {Client} from '../../../../client/interfaces/client';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../app-state';
import * as fromReport from '../../../store/selectors/report.selectors';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ClientFilters} from '../../../../client/interfaces/clientFilters';
import {FilterClientsPopoverComponent} from '../../../../client/components/filter-clients-popover/filter-clients-popover.component';
import {PopoverController} from '@ionic/angular';
import {changeClientFilters} from '../../../store/actions/report.actions';

@Component({
  selector: 'app-report-step-two-clients',
  templateUrl: './report-step-two-clients.component.html',
  styleUrls: ['./report-step-two-clients.component.css']
})
export class ReportStepTwoClientsComponent implements AfterViewInit, OnInit, OnDestroy {

  displayedColumns: string[] = ['id', 'name', 'person_type'];
  dataSource = new MatTableDataSource<Client>([]);
  filters: ClientFilters;
  filterBags = [];
  clients: Client[];
  test: any;
  private _unsubscribeAll: Subject<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    if (!this.dataSource.sort) {
      this.dataSource.sort = sort;
    }
  }
  constructor(private store: Store<AppState>, private popoverController: PopoverController) {
    this._unsubscribeAll = new Subject<any>();
    this.store.select(fromReport.selectReportClients).pipe(takeUntil(this._unsubscribeAll)).subscribe(clients => {
      this.dataSource.data = clients;
    })
    this.store.select(fromReport.selectClientFilters).pipe(takeUntil(this._unsubscribeAll)).subscribe(filters => {
      this.filters = JSON.parse(JSON.stringify(filters));
      this.generateBags();
    })
  }



  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;

  }
  ngOnInit(): void {
  }


  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  async openFilters() {
    const filters = this.filters;
    const modal = await this.popoverController.create({
      component: FilterClientsPopoverComponent,
      cssClass: 'full-popover sm:w-min sm:rounded',
      componentProps: {
        filters
      },
      backdropDismiss: true
    });
    await modal.present();
    const {data} = await modal.onDidDismiss();
    if (data) {
      this.store.dispatch(changeClientFilters({filter: data}));
    }
  }



  generateBags() {
    this.filterBags = [];
    if (this.filters.name.filter && this.filters.name.query !== '') {
      this.filterBags.push({
        name: 'name',
        bag: `Nombre: ${this.filters.name.query}`
      });
    }
    if (this.filters.payment_problems.filter && this.filters.payment_problems.query !== '') {
      if (this.filters.payment_problems.query === 'true') {
        this.filterBags.push({
          name: 'payment_problems',
          bag: `Problemas de pago: Si`
        });
      } else {
        this.filterBags.push({
          name: 'payment_problems',
          bag: `Problemas de pago: No`
        });
      }
    }
    if (this.filters.with_referrers.filter && this.filters.with_referrers.query !== '') {
      if (this.filters.with_referrers.query === 'true') {
        this.filterBags.push({
          name: 'with_referrers',
          bag: `Con referidos`
        });
      } else {
        this.filterBags.push({
          name: 'with_referrers',
          bag: `Sin referidos`
        });
      }
    }
    if (this.filters.email.filter && this.filters.email.query !== '') {
      this.filterBags.push({
        name: 'email',
        bag: `Email: ${this.filters.email.query}`
      });
    }
    if (this.filters.address.filter && this.filters.address.query !== '') {
      this.filterBags.push({
        name: 'address',
        bag: `Dirección: ${this.filters.address.query}`
      });
    }
    if (this.filters.phone.filter && this.filters.phone.query !== '') {
      this.filterBags.push({
        name: 'phone',
        bag: `Teléfono: ${this.filters.phone.query}`
      });
    }
  }
  deleteBag(i) {
    if (this.filterBags[i].name === 'name') {
      this.filters.name.filter = false;
    }
    if (this.filterBags[i].name === 'payment_problems') {
      this.filters.payment_problems.filter = false;
    }
    if (this.filterBags[i].name === 'email') {
      this.filters.email.filter = false;
    }
    if (this.filterBags[i].name === 'address') {
      this.filters.address.filter = false;
    }
    if (this.filterBags[i].name === 'phone') {
      this.filters.phone.filter = false;
    }
    if (this.filterBags[i].name === 'with_referrers') {
      this.filters.with_referrers.filter = false;
    }
    this.filterBags.splice(i, 1);
    this.store.dispatch(changeClientFilters({filter: this.filters}));
  }
}
