import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Client} from '../../../../client/interfaces/client';
import {ClientFilters} from '../../../../client/interfaces/clientFilters';
import {Subject} from 'rxjs';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../app-state';
import {PopoverController} from '@ionic/angular';
import * as fromReport from '../../../store/selectors/report.selectors';
import {takeUntil} from 'rxjs/operators';
import {FilterClientsPopoverComponent} from '../../../../client/components/filter-clients-popover/filter-clients-popover.component';
import {changeClientFilters, changeSubscriptionFilters} from '../../../store/actions/report.actions';
import {FilterSubscriptionsPopoverComponent} from '../../../../subscription/components/filter-subscriptions-popover/filter-subscriptions-popover.component';
import {SubscriptionFilters} from '../../../../subscription/interfaces/subscription-filters';
import {Subscription} from '../../../../subscription/interfaces/subscription';

@Component({
  selector: 'app-report-step-two-subscriptions',
  templateUrl: './report-step-two-subscriptions.component.html',
  styleUrls: ['./report-step-two-subscriptions.component.css']
})
export class ReportStepTwoSubscriptionsComponent implements AfterViewInit, OnInit, OnDestroy {

  displayedColumns: string[] = ['id', 'name', 'product'];
  dataSource = new MatTableDataSource<Subscription>([]);
  filters: SubscriptionFilters;
  filterBags = [];
  private _unsubscribeAll: Subject<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    if (!this.dataSource.sort) {
      this.dataSource.sort = sort;
    }
  }

  constructor(private store: Store<AppState>, private popoverController: PopoverController) {
    this._unsubscribeAll = new Subject<any>();
    this.store.select(fromReport.selectSubscriptions).pipe(takeUntil(this._unsubscribeAll)).subscribe(clients => {
      this.dataSource.data = clients;
    })
    this.store.select(fromReport.selectSubscriptionFilters).pipe(takeUntil(this._unsubscribeAll)).subscribe(filters => {
      this.filters = JSON.parse(JSON.stringify(filters));
      this.generateBags();
    })
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
  }


  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  async openFilters() {
    const filters = this.filters;
    const modal = await this.popoverController.create({
      component: FilterSubscriptionsPopoverComponent,
      cssClass: 'full-popover sm:w-min sm:rounded',
      componentProps: {
        filters
      },
      backdropDismiss: true
    });
    await modal.present();
    const {data} = await modal.onDidDismiss();
    if (data) {
      this.store.dispatch(changeSubscriptionFilters({filter: data}));
    }
  }


  generateBags() {
    this.filterBags = [];
    if (this.filters.client.filter && this.filters.client.query !== '') {
      this.filterBags.push({
        name: 'client',
        bag: `Cliente: ${this.filters.client.query}`
      });
    }
    if (this.filters.product.filter && this.filters.product.query !== '') {
      this.filters.product.query.forEach(item => {
        this.filterBags.push({
          name: 'product',
          bag: `Producto: ${item}`
        });
      });
    }
    if (this.filters.periodicity.filter && this.filters.periodicity.query !== '') {
      this.filterBags.push({
        name: 'periodicity',
        bag: `Periodicidad: ${this.filters.periodicity.query}`
      });
    }
    if (this.filters.increase_date.filter && this.filters.increase_date.query !== '') {
      this.filterBags.push({
        name: 'increase_date',
        bag: `Sin aumentos hasta: ${this.filters.increase_date.query}`
      });
    }
    if (this.filters.actual_price.filter && this.filters.actual_price.query !== '') {
      this.filterBags.push({
        name: 'actual_price',
        bag: `Precio Actual: ${this.filters.actual_price.query}`
      });
    }
    if (this.filters.status.filter && this.filters.status.query !== '') {
      this.filterBags.push({
        name: 'status',
        bag: `Estado: ${this.filters.status.query}`
      });
    }
  }

  deleteBag(i) {
    if (this.filterBags[i].name === 'client') {
      this.filters.client.filter = false;
    }
    if (this.filterBags[i].name === 'product') {
      const name = this.filterBags[i].bag.split('Producto: ');
      const index = this.filters.product.query.findIndex(item => item === name[1]);
      this.filters.product.query.splice(index, 1);
      if (this.filters.product.query.length === 0) {
        this.filters.product.filter = false;
      }

    }
    if (this.filterBags[i].name === 'periodicity') {
      this.filters.periodicity.filter = false;
    }
    if (this.filterBags[i].name === 'increase_date') {
      this.filters.increase_date.filter = false;
    }
    if (this.filterBags[i].name === 'actual_price') {
      this.filters.actual_price.filter = false;
    }
    if (this.filterBags[i].name === 'status') {
      this.filters.status.filter = false;
    }
    this.filterBags.splice(i, 1);
    this.store.dispatch(changeSubscriptionFilters({filter: this.filters}));
  }
}
