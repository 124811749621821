<div class="w-full max-w-3xl mx-auto">
  <fieldset>
    <legend class="sr-only">
      Seleccione un Módulo
    </legend>

    <div class="bg-white rounded-md -space-y-px" >
      <div class="bg-white border border-gray-300 rounded-md p-5" >
        <div class="mt-1 relative rounded-md shadow-sm">
          <label for="name" class="block text-sm font-medium text-gray-700">Nombre</label>
          <input type="text" [(ngModel)]="name" (ngModelChange)="onNameChange($event)" name="periodicDay" id="name" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 py-4 sm:text-sm border border-gray-300 rounded-md" placeholder="Nombre del Reporte">
        </div>
      </div>

      <ng-container *ngFor="let option of modules; let i = index">
        <div [class.border-gray-200]="!(moduleSelected === i)"
             [class.bg-indigo-50]="moduleSelected === i"
             [class.border-indigo-200]="moduleSelected === i"
             [class.z-10]="moduleSelected === i" class="relative border rounded-tl-md rounded-tr-md p-4 flex bg-indigo-50 border-indigo-200 z-10">
          <div class="flex items-center h-5">
            <input id="settings-option-0" name="module" type="radio" (click)="onModuleChange(i)" (keydown.space)="onModuleChange(i)" (keydown.arrowUp)="onArrowUp()" (keydown.arrowDown)="onArrowDown()" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 cursor-pointer border-gray-300" [checked]="moduleSelected === i">
          </div>
          <label for="settings-option-0" class="ml-3 flex flex-col cursor-pointer">
                <span [class.text-indigo-900]="moduleSelected === i" [class.text-gray-900]="!(moduleSelected === i)" class="block text-sm font-medium text-indigo-900">
                  {{option.name}}
                </span>
            <span [class.text-indigo-700]="moduleSelected === i" [class.text-gray-500]="!(moduleSelected === i)" class="block text-sm text-indigo-700">
                  {{option.description}}
                </span>
          </label>
        </div>
      </ng-container>



    </div>
  </fieldset>
</div>
