import { createAction, props } from '@ngrx/store';
import {Client} from '../../../client/interfaces/client';
import { ClientFilters } from '../../../client/interfaces/clientFilters';
import {Report, TableData} from '../../interfaces/report';
import {Subscription} from '../../../subscription/interfaces/subscription';
import {SubscriptionFilters} from '../../../subscription/interfaces/subscription-filters';
import {InvoiceFilters, Payment} from '../../../invoice/interfaces/payment';
import {Invoice} from '../../../client/interfaces/invoice';

export const loadReports = createAction(
  '[Report] Load Reports'
);
export const loadReportsSuccess = createAction(
  '[Report] Load Reports Success',
  props<{ reports: Report[] }>()
);

export const loadTableData = createAction(
  '[Report] Load Table Data'
);
export const loadTableDataSuccess = createAction(
  '[Report] Load Table Data Success',
  props<{ data: any[] }>()
);
export const reloadReports = createAction(
  '[Report] Reload Report'
);
export const saveReport = createAction(
  '[Report] Save Report'
);
export const changeModule = createAction(
  '[New Report] Change Module',
  props<{ module: string }>()
);
export const changeReportName = createAction(
  '[New Report] Change Name',
  props<{ name: string }>()
);
export const changeClientFilters = createAction(
  '[Client] Change Filters',
  props<{ filter: ClientFilters }>()
);
export const changeInvoiceFilters = createAction(
  '[Invoice] Change Filters',
  props<{ filter: InvoiceFilters }>()
);
export const changeSubscriptionFilters = createAction(
  '[Subscriptions] Change Filters',
  props<{ filter: SubscriptionFilters }>()
);
export const changeTableConfig = createAction(
  '[New Report] Change Table Config',
  props<{ tableData: TableData }>()
);
export const loadClients = createAction(
  '[Clients] Load'
);
export const loadClientSuccess = createAction(
  '[Clients] Load Success',
  props<{ clients: Client[] }>()
);

export const loadInvoices = createAction(
  '[Invoices] Load'
);
export const loadInvoicesSuccess = createAction(
  '[Invoices] Load Success',
  props<{ invoices: Invoice[] }>()
);
export const loadSubscriptions = createAction(
  '[Subscriptions] Load'
);
export const loadSubscriptionsSuccess = createAction(
  '[Subscriptions] Load Success',
  props<{ subscriptions: Subscription[] }>()
);
export const changeStep = createAction(
  '[New Report] Change Step',
  props<{ step: number }>()
);




