import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {PopoverController} from '@ionic/angular';
import {ClientFilters} from '../../interfaces/clientFilters';
import {Client} from '../../interfaces/client';
import {ClientService} from '../../services/client.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-filter-clients-popover',
  templateUrl: './filter-clients-popover.component.html',
  styleUrls: ['./filter-clients-popover.component.scss'],
})
export class FilterClientsPopoverComponent implements OnInit, OnDestroy {

  destroy$: Subject<any>;
  @Input() filters: ClientFilters = {
    name: {
      filter: false,
      query: ''
    },
    address: {
      filter: false,
      query: ''
    },
    postalCode: {
      filter: false,
      query: ''
    },
    phone: {
      filter: false,
      query: ''
    },
    user: {
      filter: false,
      query: ''
    },
    email: {
      filter: false,
      query: ''
    },
    payment_problems: {
      filter: false,
      query: ''
    },
    payment_problems_min_months: {
      filter: false,
      query: ''
    },
    payment_problems_max_months: {
      filter: false,
      query: ''
    },
    with_referrers: {
      filter: false,
      query: ''
    },
    referred_by: {
      filter: false,
      query: ''
    },
    leads: {
      filter: false,
      query: ''
    },
    document: {
      filter: false,
      query: ''
    }
  };
  clientSelected: Client;
  clients: Client[] = [];
  constructor(private popoverController: PopoverController, private clientService: ClientService) {
    this.destroy$ = new Subject<any>();
  }

  ngOnInit() {
    this.clientService.getClients().pipe(takeUntil(this.destroy$)).subscribe(clients => {
      this.clients = clients;
    })
    console.log(this.filters);
  }

  async save() {
    const popover = await this.popoverController.getTop();
    await popover.dismiss(this.filters);
  }
  async close() {
    const popover = await this.popoverController.getTop();
    await popover.dismiss();
  }
  onClientSelect(client) {
    if (client?.id) {
      this.filters.referred_by.query = client.name;
      this.clientSelected = client;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
