import {PopoverController} from '@ionic/angular';

export class PopoverMain {
  errors: any;

  constructor(private popoverController: PopoverController) {}

  async closePopover(data = undefined) {
    const popover = await this.popoverController.getTop();
    if (popover) {
      await popover.dismiss(data);
    }
  }
}
