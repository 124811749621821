import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromReport from '../reducers/report.reducer';

export const selectReportState = createFeatureSelector<fromReport.State>(
  fromReport.reportFeatureKey
);
export const selectReportStep = createSelector(
  selectReportState,
  (state: fromReport.State) => state.step
);
export const selectReport = createSelector(
  selectReportState,
  (state: fromReport.State) => state.report
);
export const selectInvoiceFilters = createSelector(
  selectReportState,
  (state: fromReport.State) => state.report.invoiceFilters
);
export const selectReports = createSelector(
  selectReportState,
  (state: fromReport.State) => state.reports
);

export const selectReportModule = createSelector(
  selectReportState,
  (state: fromReport.State) => state.report.module
);
export const selectReportClients = createSelector(
  selectReportState,
  (state: fromReport.State) => state.clients
);
export const selectReportInvoices = createSelector(
  selectReportState,
  (state: fromReport.State) => state.invoices
);

export const selectClientFilters = createSelector(
  selectReportState,
  (state: fromReport.State) => state.report.clientFilters
);
export const selectSubscriptionFilters = createSelector(
  selectReportState,
  (state: fromReport.State) => state.report.subscriptionFilters
);

export const selectTableConfig = createSelector(
  selectReportState,
  (state: fromReport.State) => state.report.tableData
);
export const selectReportName = createSelector(
  selectReportState,
  (state: fromReport.State) => state.report.name
);
export const selectTableData = createSelector(
  selectReportState,
  (state: fromReport.State) => state.data
);
export const selectSubscriptions = createSelector(
  selectReportState,
  (state: fromReport.State) => state.subscriptions
);
