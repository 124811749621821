import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { RouterModule } from '@angular/router';
import { ButtonFullDirective } from './directives/button-full.directive';
import { ErrorAlertComponent } from './components/error-alert/error-alert.component';
import { TailInputDirective } from './directives/tail-input.directive';
import { FormsModule } from '@angular/forms';
import { NightModeComponent } from './components/navbar/night-mode/night-mode.component';
import { IonicModule } from "@ionic/angular";
import { TailDateInputComponent } from './components/tail-date-input/tail-date-input.component';
import { TooltipDirective } from './directives/tooltip.directive';
import { SearchClientPipe } from './pipes/search-by-name.pipe';
import { SearchSubscriptionPipe } from './pipes/search-subscription.pipe';
import { SuccessPopoverComponent } from './components/success-popover/success-popover.component';
import { DangerPopoverComponent } from './components/danger-popover/danger-popover.component';
import { WizardStepComponent } from './components/wizard-step/wizard-step.component';
import { ModuleHeaderComponent } from './components/module-header/module-header.component';
import { NoResultsImageComponent } from './components/no-results-image/no-results-image.component';
import { StoreModule } from "@ngrx/store";
import * as fromShared from "./store/reducers/shared.reducer";
import { EffectsModule } from "@ngrx/effects";
import { SharedEffects } from "./store/effects/shared.effects";
import { ProductSelectorComponent } from './components/form/product-selector/product-selector.component';
import { ProductSelectorItemComponent } from './components/form/product-selector-item/product-selector-item.component';
import { SearchByNamePipe } from "./directives/search-by-name.pipe";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { SubscriptionClientComponent } from './components/subscription-client/subscription-client.component';
import { ClientSelectorComponent } from './components/subscription-client/client-selector/client-selector.component';
import { ClientSelectorItemComponent } from '../modules/subscription/components/client-selector-item/client-selector-item.component';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";


@NgModule({
  declarations: [
    PageNotFoundComponent,
    ButtonFullDirective,
    ErrorAlertComponent,
    TailInputDirective,
    NightModeComponent,
    TailDateInputComponent,
    TooltipDirective,
    SearchClientPipe,
    SearchByNamePipe,
    SearchSubscriptionPipe,
    SuccessPopoverComponent,
    DangerPopoverComponent,
    WizardStepComponent,
    ModuleHeaderComponent,
    NoResultsImageComponent,
    ProductSelectorComponent,
    ProductSelectorItemComponent,
    SubscriptionClientComponent,
    ClientSelectorComponent,
    ClientSelectorItemComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    IonicModule,
    StoreModule.forFeature(fromShared.featureKey, fromShared.reducer),
    EffectsModule.forFeature([SharedEffects]),
    MatSelectModule,
    MatIconModule,
    MatInputModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatButtonModule,
    GooglePlaceModule
  ],
  exports: [
    ButtonFullDirective,
    ErrorAlertComponent,
    TailInputDirective,
    NightModeComponent,
    TailDateInputComponent,
    TooltipDirective,
    SearchClientPipe,
    SearchSubscriptionPipe,
    WizardStepComponent,
    ModuleHeaderComponent,
    NoResultsImageComponent,
    ProductSelectorComponent,
    ProductSelectorItemComponent,
    SearchByNamePipe,
    SubscriptionClientComponent,
    ClientSelectorComponent,
    ClientSelectorItemComponent
  ],
  providers: [
    DatePipe
  ]
})
export class SharedModule { }
