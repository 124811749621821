import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import {Observable, throwError, BehaviorSubject, from} from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import {SessionService} from '../services/session.service';
import {Router} from '@angular/router';
import {ToastController} from '@ionic/angular';
import Swal from 'sweetalert2';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private session: SessionService, private router: Router, public toastController: ToastController) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.session.getToken()).pipe(
      switchMap(token => {
        if (token != null) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`
            }
          });
        }
        request = request.clone({
          headers: request.headers.set('Accept', 'application/json')
        });
        return next.handle(request).pipe(catchError(err => {
          if (err.status === 401) {
              this.session.removeToken().then(data => {
                this.router.navigateByUrl('/auth/login');
              });
          }
          if (err.status === 500 || err.status === 0) {
            //this.presentToast('Ocurrió un error al conectarse con el servidor');
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Ocurrió un error al conectarse con el servidor.',
            });
          }
          return throwError(err);
        }));
      }));
  }

  private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }
  async presentToast(text) {
    const toast = await this.toastController.create({
      message: text,
      duration: 2000
    });
    toast.present();
  }
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return from(this.session.getToken()).pipe(
        switchMap((token: string) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token);
          return next.handle(this.addToken(request, token));
        }));

    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }
}
