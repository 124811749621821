import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Client } from 'src/app/modules/client/interfaces/client';
import { Periodicity, Product } from 'src/app/modules/product/interfaces/product';
import { ProductService } from 'src/app/modules/product/services/product.service';
import { SubscriptionService } from 'src/app/modules/subscription/services/subscription.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-subscription-inform-page',
  templateUrl: './subscription-inform-page.component.html',
  styleUrls: ['./subscription-inform-page.component.css']
})
export class SubscriptionInformPageComponent implements OnInit {

  private unsubscribeAll: Subject<any>;
  destroy$: Subject<any> = new Subject<any>();
  loading = false;
  users: any[] = [];
  suscr: any[] = [];
  dateNow: any = new Date();
  clients: Client[] = [];
  products: Product[] = [];
  periodicitys: Periodicity[] = [];
  formGroup: FormGroup = this.fb.group({
    from: [''],
    to: [''],
    clients: [''],
    statuses: [''],
    ignore_refered: [''],
    payment_method: [''],
    veraz_score: [''],
    veraz_score_comp: ['0'],
    products: [''],
    periodicity_id: [''],
    city: ['']
  });
  constructor(private _sharedService: SharedService,
    private subscriptionService: SubscriptionService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private productService: ProductService,
  ) {
    this.unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this._sharedService.getClients().pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.clients = res;
    });

    this.productService.getProducts().pipe(takeUntil(this.unsubscribeAll)).subscribe(res => {
      this.products = res;
    });

    this.productService.getPeriodicity().pipe(takeUntil(this.unsubscribeAll)).subscribe(res => {
      this.periodicitys = res;
    });

    // this.dateNow = this.datePipe.transform(this.dateNow, "yyyy-MM-dd");
    // this.formGroup.controls['from'].setValue(this.dateNow);
    // this.formGroup.controls['to'].setValue(this.dateNow);

    const queryParams = this.route.snapshot.queryParamMap;

    if(queryParams){
       
      // Verifica si hay parámetros específicos y aplica los filtros
      if (queryParams.has('desde')) {
        this.formGroup.controls['from'].setValue(queryParams.get('desde'));
      }

      if (queryParams.has('hasta')) {
        this.formGroup.controls['to'].setValue(queryParams.get('hasta'));
      }

      if (queryParams.has('estado')) {
        this.formGroup.controls['statuses'].setValue(queryParams.get('estado'));
      }
    }

    this.getData();
  }

  getData() {
    var data = this.formGroup.value;
    this.loading = true;
    this.subscriptionService.inform(data).pipe(takeUntil(this.destroy$)).subscribe(async res => {
      this.loading = false;
      if (res && res.report) {
        this.suscr = [];
        this.suscr = res.report;
      }
    }, err => {
      this.loading = false;
      console.log(err);
    });
  }

  filtrar() {
    console.log('filtro', this.formGroup.value);
    this.getData();
  }

  onClientSelect(client) {
    if (client?.id) {
      this.formGroup.controls['clients'].setValue(client.name)
    } else {
      this.formGroup.controls['clients'].setValue('')
    }
  }
  downloadFile() {
    var data = this.formGroup.value
    data['excelExport'] = true;
    this.subscriptionService.informExcel(data).subscribe(res => {
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(res);
      link.download = 'Informe de suscripciones';
      link.click();
    });
  }
}