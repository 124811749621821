<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="end">
      <ion-button (click)="save()">Aplicar</ion-button>
    </ion-buttons>
    <ion-buttons slot="start">
      <ion-button (click)="close()"><i class="fa-solid fa-xmark"></i></ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<div class="px-4 py-5 bg-white sm:p-6 w-96">
  <div class="grid grid-cols-6 gap-3">
    <div class="col-span-6">
      <label class="block text-sm font-semibold text-gray-700"><ion-checkbox [(ngModel)]="filters.referred_by.filter" color="primary" class="align-middle mr-2"></ion-checkbox> <span class="align-middle">Referido por</span></label>
      <app-client-selector *ngIf="filters.referred_by.filter" [clients]="clients" [selected]="clientSelected" (clientSelected)="onClientSelect($event)"></app-client-selector>
    </div>
    <div class="col-span-6">
      <label for="payment_problems" class="block text-sm font-semibold text-gray-700"><ion-checkbox [(ngModel)]="filters.payment_problems.filter" color="primary" class="align-middle mr-2"></ion-checkbox> <span class="align-middle">Filtrar por Problemas de Pago</span></label>
      <select *ngIf="filters.payment_problems.filter" name="payment_problems" id="payment_problems" [(ngModel)]="filters.payment_problems.query" autocomplete="name" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
        <option value="true">Con problemas de pago</option>
        <option value="false">Sin problemas de pago</option>
      </select>
    </div>
    <div class="col-span-6">
      <label for="phone" class="block text-sm font-semibold text-gray-700"><ion-checkbox [(ngModel)]="filters.payment_problems_min_months.filter" color="primary" class="align-middle mr-2"></ion-checkbox> <span class="align-middle">Filtrar por meses en problemas de pago (Min)</span></label>
      <input *ngIf="filters.payment_problems_min_months.filter" type="number" name="payment_problems_min_months" id="payment_problems_min_months" placeholder="Minimo de meses en problemas de pago" [(ngModel)]="filters.payment_problems_min_months.query" autocomplete="given-name" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
    </div>
    <div class="col-span-6">
      <label for="phone" class="block text-sm font-semibold text-gray-700"><ion-checkbox [(ngModel)]="filters.payment_problems_max_months.filter" color="primary" class="align-middle mr-2"></ion-checkbox> <span class="align-middle">Filtrar por meses en problemas de pago (Max)</span></label>
      <input *ngIf="filters.payment_problems_max_months.filter" type="number" name="payment_problems_max_months" id="payment_problems_max_months" placeholder="Maximo de meses en problemas de pago" [(ngModel)]="filters.payment_problems_max_months.query" autocomplete="given-name" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
    </div>
    <div class="col-span-6">
      <label for="name" class="block text-sm font-semibold text-gray-700"><ion-checkbox [(ngModel)]="filters.name.filter" color="primary" class="align-middle mr-2"></ion-checkbox> <span class="align-middle">Filtrar por Nombre</span></label>
      <input *ngIf="filters.name.filter" type="text" name="name" id="name" placeholder="Nombre" [(ngModel)]="filters.name.query" autocomplete="name" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
    </div>
    <div class="col-span-6">
      <label for="document" class="block text-sm font-semibold text-gray-700"><ion-checkbox [(ngModel)]="filters.document.filter" color="primary" class="align-middle mr-2"></ion-checkbox> <span class="align-middle">Filtrar por Documento</span></label>
      <input *ngIf="filters.document.filter" type="text" name="document" id="document" placeholder="Documento" [(ngModel)]="filters.document.query" autocomplete="name" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
    </div>
    <div class="col-span-6">
      <label for="address" class="block text-sm font-semibold text-gray-700"><ion-checkbox [(ngModel)]="filters.address.filter" color="primary" class="align-middle mr-2"></ion-checkbox> <span class="align-middle">Filtrar por Dirección</span></label>
      <input *ngIf="filters.address.filter" type="text" name="address" id="address" placeholder="Dirección" [(ngModel)]="filters.address.query" autocomplete="address" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
    </div>
    <div class="col-span-6">
      <label for="postalCode" class="block text-sm font-semibold text-gray-700"><ion-checkbox [(ngModel)]="filters.postalCode.filter" color="primary" class="align-middle mr-2"></ion-checkbox> <span class="align-middle">Filtrar por Código postal</span></label>
      <input *ngIf="filters.postalCode.filter" type="text" name="postalCode" id="postalCode" placeholder="Código postal" [(ngModel)]="filters.postalCode.query" autocomplete="postalCode" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
    </div>
    <div class="col-span-6">
      <label for="phone" class="block text-sm font-semibold text-gray-700"><ion-checkbox [(ngModel)]="filters.phone.filter" color="primary" class="align-middle mr-2"></ion-checkbox> <span class="align-middle">Filtrar por Teléfono</span></label>
      <input *ngIf="filters.phone.filter" type="text" name="phone" id="phone" placeholder="Teléfono" [(ngModel)]="filters.phone.query" autocomplete="given-name" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
    </div>
    <div class="col-span-6">
      <label for="email" class="block text-sm font-semibold text-gray-700"><ion-checkbox [(ngModel)]="filters.email.filter" color="primary" class="align-middle mr-2"></ion-checkbox> <span class="align-middle">Filtrar por Email</span></label>
      <input *ngIf="filters.email.filter" type="text" name="email" id="email" placeholder="Email" [(ngModel)]="filters.email.query" autocomplete="given-name" tail-input>
    </div>
    <div class="col-span-6">
      <label for="user" class="block text-sm font-semibold text-gray-700"><ion-checkbox [(ngModel)]="filters.user.filter" color="primary" class="align-middle mr-2"></ion-checkbox> <span class="align-middle">Filtrar por Usuario</span></label>
      <input *ngIf="filters.user.filter" type="text" name="user" id="user" placeholder="Usuario" [(ngModel)]="filters.user.query" autocomplete="given-name" tail-input>
    </div>
    <div class="col-span-6">
      <label for="payment_problems" class="block text-sm font-semibold text-gray-700"><ion-checkbox [(ngModel)]="filters.with_referrers.filter" color="primary" class="align-middle mr-2"></ion-checkbox> <span class="align-middle">Con Referidos</span></label>
      <select *ngIf="filters.with_referrers.filter" name="with_referrers" id="with_referrers" [(ngModel)]="filters.with_referrers.query" autocomplete="name" tail-input>
        <option value="true">Con Referidos</option>
        <option value="false">Sin Referidos</option>
      </select>
    </div>

  </div>
</div>
<ion-footer>
  <ion-toolbar color="primary">
    <ion-buttons slot="end">
      <ion-button (click)="save()">Aplicar</ion-button>
    </ion-buttons>
    <ion-buttons slot="start">
      <ion-button (click)="close()"><i class="fa-solid fa-xmark"></i></ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>