import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { PopoverController, ToastController } from '@ionic/angular';
import {
  Subscription,
  SubscriptionDocument,
} from '../../interfaces/subscription';
import { SubscriptionDetailPageService } from '../../pages/subscription-detail-page/subscription-detail-page.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-create-document-popover',
  templateUrl: './create-document-popover.component.html',
  styleUrls: ['./create-document-popover.component.css'],
})
export class CreateDocumentPopoverComponent implements OnInit {
  @Input() subscription: Subscription;
  @Input() document: SubscriptionDocument = {};

  attachedTypes: any[] = [];
  constructor(
    private popoverController: PopoverController,
    private toastController: ToastController,
    private service: SubscriptionDetailPageService
  ) {}

  ngOnInit(): void {
    this.document.subscription_id = this.subscription.id;
    this.service.getAttachedTypes().subscribe((res) => {
      this.attachedTypes = res;
    });

    if (!this.document.id) {
      this.document.phone = this.subscription.client.phone;
      this.document.date = new Date().toISOString().slice(0, 10);
      this.document.hour = "10:00hs a 17:00hs";
    }

    console.log('this.subscription: ', this.subscription);
  }
  async presentToast(message) {
    const toast = await this.toastController.create({
      message,
      duration: 2000,
    });
    await toast.present();
  }

  sendForm() {
    if (this.document.id) {
      this.service
        .updateDocument(this.subscription, this.document)
        .pipe(tap((res) => console.log('Actualización completada', res)))
        .subscribe((res) => {
          this.closeModal(res);
        });
    } else {
      this.service
        .storeDocument(this.subscription, this.document)
        .pipe(tap((res) => console.log('Nuevo Documento Creado', res)))
        .subscribe((res) => {
          this.closeModal(res);
        });
    }
  }
  async closeModal(client) {
    const modal = await this.popoverController.getTop();
    await modal.dismiss(client);
  }
  async close() {
    const modal = await this.popoverController.getTop();
    await modal.dismiss();
  }
}
