import { TailRoute } from '../shared/models/route';

export const routes: TailRoute[] = [
  {
    name: 'Dashboard',
    icon: 'apps',
    route: '/home',
  },
  {
    name: 'Clientes',
    icon: 'people',
    route: '/clients',
    children: [
      {
        name: 'Alta Cliente',
        icon: 'apps',
        route: '/clients/create',
      },
      {
        name: 'Ver Clientes',
        icon: 'apps',
        route: '/clients/list',
      },
      {
        name: 'Interesados',
        icon: 'apps',
        route: '/clients/leads',
      },
      {
        name: 'Agregar Suscripción',
        icon: 'apps',
        route: '/subscriptions/new',
      },
      {
        name: 'Ver Suscripciones',
        icon: 'apps',
        route: '/subscriptions/list',
      },
      {
        name: 'Ver Stop Debits',
        icon: 'apps',
        route: '/stop-debit',
      },
      {
        name: 'Problemas de Pago',
        icon: 'apps',
        route: '/clients/paymentProblems',
      },
    ],
  },
  /* {
    name: 'Suscripciones',
    icon: 'people',
    route: '/subscriptions',
    children: [
      {
        name: 'Listado',
        icon: 'apps',
        route: '/subscriptions/list',
      },

    ],
  }, */

  {
    name: 'Productos',
    icon: 'card',
    route: '/products',
    children: [
      {
        name: 'Listado',
        icon: 'apps',
        route: '/products/list',
      },
      {
        name: 'Tipos de productos',
        icon: 'apps',
        route: '/products/types',
      },
      {
        name: 'Periodicidades',
        icon: 'pricetags-outline',
        route: '/products/periodicities',
      },
      {
        name: 'Bonificaciones',
        icon: 'cash-outline',
        route: '/products/discount',
      },
    ],
  },
  {
    name: 'Aumentos',
    icon: 'cash-outline',
    route: '/increases',
    children: [
      {
        name: 'Pendientes',
        icon: 'build-outline',
        route: '/increases/pending',
      },
      {
        name: 'Histórico',
        icon: 'build-outline',
        route: '/increases/list',
      },
    ],
  },
  {
    name: 'Mantenimientos',
    icon: 'build-outline',
    route: '/maintenances',
    children: [
      {
        name: 'Pendientes',
        icon: 'calendar-outline',
        route: '/maintenances/pending',
      },
      {
        name: 'Histórico',
        icon: 'calendar-outline',
        route: '/maintenances/list',
      },
    ],
  },
  {
    name: 'Agenda',
    icon: 'calendar-outline',
    route: '/programming',
  },
  {
    name: 'Inventario',
    icon: 'albums-outline',
    route: '/units',
    children: [
      {
        name: 'Unidades',
        icon: 'apps',
        route: '/units/list',
      },
      {
        name: 'Partes',
        icon: 'apps',
        route: '/units/consumables/list',
      },
      {
        name: 'Stock',
        icon: 'apps',
        route: '/units/stock',
      },
      {
        name: 'Locaciones',
        icon: 'apps',
        route: '/units/locations',
      },
      {
        name: 'Metadata',
        icon: 'apps',
        route: '/units/metadata',
      },
    ],
  },
  {
    name: 'Comunicaciones',
    icon: 'mail-outline',
    route: '/mails',
    children: [
      {
        name: 'Listado',
        icon: 'calendar-outline',
        route: '/mails/list',
      },
    ],
  },
  {
    name: 'Reportes',
    icon: 'bar-chart-outline',
    route: '/reports',
    children: [
      {
        name: 'Mensuales',
        icon: 'bar-chart-outline',
        route: '/reports/monthly',
      },
      {
        name: 'Cuenta corriente',
        icon: 'bar-chart-outline',
        route: '/reports/invoices',
      },
      {
        name: 'Ventas',
        icon: 'bar-chart-outline',
        route: '/reports/sells',
      },
      {
        name: 'Suscripciones',
        icon: 'bar-chart-outline',
        route: '/reports/subscriptions',
      },
      {
        name: 'Bajas',
        icon: 'bar-chart-outline',
        route: '/reports/bajas',
      },
      {
        name: 'Personalizables',
        icon: 'bar-chart-outline',
        route: '/reports/list',
      },
    ],
  },
  {
    name: 'Usuarios',
    icon: 'person-circle-outline',
    route: '/users',
    children: [
      {
        name: 'Listado',
        icon: 'apps',
        route: '/users/list',
      },
    ],
  },
];
