import { Component, OnInit } from '@angular/core';
import { Client } from 'src/app/modules/client/interfaces/client';
import { ClientService } from 'src/app/modules/client/services/client.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-invoices-inform-page',
  templateUrl: './invoices-inform-page.component.html',
  styleUrls: ['./invoices-inform-page.component.css']
})
export class InvoicesInformPageComponent implements OnInit {
  private destroy$: Subject<any> = new Subject<any>();
  invoices: any[] = [];
  cliente: string;
  from: any;
  to: any;
  producto: string;
  productos: any[] = [];
  clients: Client[] = [];
  formGroup: FormGroup = this.fb.group({
    salida: [''],
    producto: [''],
    cliente: [''],
    from: [''],
    to: ['']
  });

  constructor(private clientService: ClientService, private _sharedService: SharedService, private fb: FormBuilder,) { }
  ngOnInit(): void {
    this._sharedService.getClients().pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.clients = res;
    });
    this.getInvoices();
  }

  getInvoices() {
    var data = this.formGroup.value;
    this.clientService.getInvoices(data).subscribe(res => {
      this.invoices = res.totals;
      this.productos = []
      this.invoices.forEach(r => {
        if (r.suscripciones) {
          r.suscripciones.forEach(s => {
            if (!this.productos.includes(s.product_name)) {
              this.productos.push(s.product_name);
            }
          });
        }
      });
    });
  }

  filtrar() {
    console.log('filtro', this.formGroup.value);
    this.getInvoices();
  }

  selectClient(client) {
    if (client?.id) {
      this.formGroup.controls['cliente'].setValue(client.name)
    } else {
      this.formGroup.controls['cliente'].setValue('')
    }
  }

  downloadFile() {
    var data = this.formGroup.value
    data['excelExport'] = true;
    this.clientService.getInvoicesExcel(data).subscribe(res => {
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(res);
      link.download = 'Informe cuenta corriente';
      link.click();
    });
  }
}
