<div *ngIf="loading" class="loading">
  <span class="loader"></span>
</div>
<div class="self-center hidden sm:block mb-2">
  <button
    (click)="goBack()"
    class="text-white bg-blue-600 opacity-90 hover:opacity-100 font-semibold py-1 px-3 rounded"
  >
    <i class="fa-solid fa-arrow-left"></i> Atras
  </button>
</div>
<div class="dark:bg-gray-600 bg-blue-500 w-full shadow-lg">
  <div
    class="flex mx-5 sm:mx-10 justify-between py-5 text-white dark:text-gray-200 relative"
  >
    <div>
      <h2 class="my-0 text-lg font-bold">Listado de Stop Debits</h2>
    </div>
  </div>
</div>
<div class="my-2">
  <div class="w-full">
    <mat-table [dataSource]="dataSource" matSort class="w-full">
      <ng-container matColumnDef="subscriptionID">
        <mat-header-cell *matHeaderCellDef mat-sort-header> # </mat-header-cell>
        <mat-cell *matCellDef="let stopDebit">
          {{ stopDebit.subscription.id }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="clientName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Nombre del Cliente
        </mat-header-cell>
        <mat-cell *matCellDef="let stopDebit">
          {{ stopDebit.client.name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="subscriptionProduct">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Producto de la Suscripción
        </mat-header-cell>
        <mat-cell *matCellDef="let stopDebit">
          {{ stopDebit.subscription.product.name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="subscriptionPrice">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Precio de la Suscripción
        </mat-header-cell>
        <mat-cell *matCellDef="let stopDebit">
          ${{ stopDebit.subscription.periodic_price | number : "1.2-2" }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="periods">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Cantidad de Periodos
        </mat-header-cell>
        <mat-cell *matCellDef="let stopDebit">
          {{ stopDebit.periods }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="activateAt">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Próximo cobro
        </mat-header-cell>
        <mat-cell *matCellDef="let stopDebit">
          {{ stopDebit.activate_at | date : "d/M/y" }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="userName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Usuario
        </mat-header-cell>
        <mat-cell *matCellDef="let stopDebit">
          {{ stopDebit.user.name }}
        </mat-cell>
      </ng-container>

      <!-- <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef> Acciones </mat-header-cell>
          <mat-cell *matCellDef="let stopDebit">
            <button
              class="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600"
            >
              Reactivar
            </button>
          </mat-cell>
        </ng-container> -->

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="goToDetail(row.subscription.id)"
        class="cursor-pointer hover:bg-gray-100"
      ></mat-row>
    </mat-table>

    <mat-paginator
      [pageSizeOptions]="[10, 20, 30]"
      [pageSize]="10"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>
