<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-button  (click)="close()">Cancelar</ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button slot="end" (click)="save()">Aplicar</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<div class="px-4 py-5 bg-white sm:p-6 w-96">
  <div class="grid grid-cols-6 gap-3">
    <div class="col-span-6">
      <label for="name" class="block text-sm font-semibold text-gray-700"><ion-checkbox [(ngModel)]="filters.client.filter" color="primary" class="align-middle mr-2"></ion-checkbox> <span class="align-middle">Filtrar por Cliente</span></label>
      <input *ngIf="filters.client.filter" type="text" name="name" id="name" placeholder="Nombre" [(ngModel)]="filters.client.query" autocomplete="name" tail-input>
    </div>
    <div class="col-span-6">
      <label for="product_id" class="block text-sm font-semibold text-gray-700"><ion-checkbox [(ngModel)]="filters.product.filter" color="primary" class="align-middle mr-2"></ion-checkbox> <span class="align-middle">Filtrar por Producto</span></label>
      <ng-container *ngFor="let product of filters.product.query; let i = index">
        <ion-chip color="primary">
          <ion-label>{{product}}</ion-label>
          <ion-icon name="close" (click)="deleteProduct(i)"></ion-icon>
        </ion-chip>
      </ng-container>
      <select *ngIf="filters.product.filter" id="product_id" name="product_id" (ngModelChange)="onProductSelect($event)" [(ngModel)]="productModel" autocomplete="product_id" tail-input>
        <option *ngFor="let product of products" value="{{product.name}}">{{product.name}}</option>
      </select>
    </div>
    <div class="col-span-6">
      <label for="periodicity_id" class="block text-sm font-semibold text-gray-700"><ion-checkbox [(ngModel)]="filters.periodicity.filter" color="primary" class="align-middle mr-2"></ion-checkbox> <span class="align-middle">Filtrar por Periodicidad</span></label>
      <select *ngIf="filters.periodicity.filter" id="periodicity_id" name="periodicity_id" [(ngModel)]="filters.periodicity.query" autocomplete="periodicity_id" tail-input>
        <option *ngFor="let periodicity of periodicities" [value]="periodicity.name">{{periodicity.name}}</option>
      </select>
    </div>
    <div class="col-span-6">
      <label for="status" class="block text-sm font-semibold text-gray-700"><ion-checkbox [(ngModel)]="filters.status.filter" color="primary" class="align-middle mr-2"></ion-checkbox> <span class="align-middle">Filtrar por Estado</span></label>
      <select *ngIf="filters.status.filter" id="status" name="status" [(ngModel)]="filters.status.query" autocomplete="status" tail-input>
        <option *ngFor="let status of statusOptions" [value]="status">{{status}}</option>
      </select>
    </div>
    <div class="col-span-6">
      <label for="actual_price" class="block text-sm font-semibold text-gray-700"><ion-checkbox [(ngModel)]="filters.actual_price.filter" color="primary" class="align-middle mr-2"></ion-checkbox> <span class="align-middle">Filtrar por Precio</span></label>
      <div *ngIf="filters.actual_price.filter" class="mt-1 relative rounded-md shadow-sm">
        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <span class="text-gray-500 sm:text-sm">
                        $
                      </span>
        </div>
        <input type="number" name="install_price" [(ngModel)]="filters.actual_price.query" id="actual_price" tail-input class="pl-7 pr-12" placeholder="0.00">
        <div class="absolute inset-y-0 right-0 flex items-center">
          <label for="install_currency" class="sr-only">Moneda</label>
          <select id="install_currency" name="period_currency" class="focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md">
            <option>ARS</option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-span-6">
      <label for="increase_date" class="block text-sm font-semibold text-gray-700"><ion-checkbox [(ngModel)]="filters.increase_date.filter" color="primary" class="align-middle mr-2"></ion-checkbox> <span class="align-middle">Filtrar sin aumentos hasta</span></label>
      <input type="date" *ngIf="filters.increase_date.filter" [(ngModel)]="filters.increase_date.query" id="increase_date" class="appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent flex-1"/>
    </div>
  </div>
</div>
