<div class="bg-white rounded-md shadow">
    <div *ngIf="loading" class="text-center flex items-center justify-center mx-auto">
        <mat-spinner></mat-spinner>
    </div>
    <form [formGroup]="formGroup">
        <h1 class="flex items-center mx-5 sm:mx-10 justify-between text-xl font-semibold text-blue-900 py-4">Suscripciones
        </h1>
        <div class="filtros flex-wrap items-end justify-around px-8">
            <div class="form-group">
                <strong>Estado</strong>
                <select class="form-control text-base" formControlName="statuses" name="statuses">
                    <option value=""></option>
                    <option value="Solicitado">Solicitado</option>
                    <option value="Programado">Programado</option>
                    <option value="Instalado">Instalado</option>
                    <option value="Dado de Baja">Dado de Baja</option>
                </select>
            </div>
            <div class="form-group">
                <strong>Cliente</strong>
                <app-client-selector [clients]="clients" (clientSelected)="onClientSelect($event)">
                </app-client-selector>
            </div>
            <div class="form-group">
                <strong>Producto</strong>
                <select class="form-control text-base" formControlName="products" name="products">
                    <option value=""></option>
                    <option *ngFor="let option of products" [value]="option.id">{{option?.name}}</option>
                </select>
            </div>
            <div class="form-group">
                <strong>Periodicidad</strong>
                <select class="form-control text-base" formControlName="periodicity_id" name="periodicity_id">
                    <option value=""></option>
                    <option *ngFor="let option of periodicitys" [value]="option.id">{{option?.name}}</option>
                </select>
            </div>
            <div class="form-group">
                <strong>Desde</strong>
                <input type="date" formControlName="from" name="from" class="form-control appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent flex-1"
                />
            </div>
            <div class="form-group">
                <strong>Hasta</strong>
                <input type="date" formControlName="to" name="to" class="form-control appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent flex-1"
                />
            </div>
            <div class="form-group">
                <strong>Score de veraz</strong>
                <div class="flex">
                    <select class="form-control w-28" formControlName="veraz_score_comp" name="veraz_score_comp">
                        <option selected value="0">Mayor a</option>
                        <option value="1">Menor a</option>
                    </select>
                    <input type="text" formControlName="veraz_score" name="veraz_score" class="ml-2 appearance-none border border-gray-300 w-28 py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent flex-1"
                    />
                </div>
            </div>
            <div class="form-group">
                <strong>Ciudad</strong>
                <input type="text" formControlName="city" name="city" class="appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent flex-1"
                />
            </div>
            <div class="flex form-group h-12 items-center">
                <label for="bordered-checkbox-2" class="dark:text-gray-300 font-medium mb-0 ml-2 mr-3 text-gray-900 text-sm">Ignorar
                    referido</label>
                <input id="bordered-checkbox-2" type="checkbox" value="" formControlName="ignore_refered" name="ignore_refered" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
            </div>
            <div class="form-group">
                <strong>Metodo de pago</strong>
                <select class="form-control text-base" formControlName="payment_method" name="payment_method">
                    <option value=""></option>
                    <option value="Increase">Tarjeta de Crédito / CBU</option>
                    <option value="Cheque">Cheque</option>
                    <option value="Efectivo">Efectivo</option>
                </select>
            </div>
            <div class="form-group flex items-end">
                <ion-button size="small" (click)="filtrar()" class="button button-small button-solid capitalize h-9 hydrated ion-activatable ion-focusable md my-0">Filtrar
                    <ion-icon name="filter" class="ml-3 text-sm">
                    </ion-icon>
                </ion-button>
                <ion-button size="small" (click)="downloadFile()" class="button button-small button-solid capitalize h-9 hydrated ion-activatable ion-focusable md my-0">Descargar
                    <ion-icon name="cloud-download-outline" class="ml-3 text-sm">
                    </ion-icon>
                </ion-button>
            </div>
        </div>
        <table class="hidden sm:table min-w-full table-auto">
            <thead class="text-sm">
                <tr class="bg-gray-800">
                    <th class="py-2"><span class="text-gray-300">Id Suscripción</span></th>
                    <th class="py-2"><span class="text-gray-300">Servicio</span></th>
                    <th class="py-2"><span class="text-gray-300">Estado</span></th>
                    <th class="py-2"><span class="text-gray-300">Cliente</span></th>
                    <th class="py-2"><span class="text-gray-300">Forma de pago</span></th>
                    <th class="py-2"><span class="text-gray-300">Dirección</span></th>
                    <th class="py-2"><span class="text-gray-300">Piso</span></th>
                    <th class="py-2"><span class="text-gray-300">Número</span></th>
                    <th class="py-2"><span class="text-gray-300">Localidad</span></th>
                    <th class="py-2"><span class="text-gray-300">Fecha Solicitud</span></th>
                    <th class="py-2"><span class="text-gray-300">Fecha Instalación</span></th>
                    <th class="py-2"><span class="text-gray-300">Precio Único</span></th>
                    <th class="py-2"><span class="text-gray-300">Precio Mensual</span></th>
                    <th class="py-2"><span class="text-gray-300">Veraz</span></th>
                </tr>
            </thead>
            <tbody *ngIf="suscr?.length > 0 && !loading;else other_content2" class="bg-gray-200">
                <tr class="bg-white border-4 border-gray-200 text-sm" *ngFor="let sus of suscr">
                    <td class="text-center">
                        <span class="text-center ml-2">{{sus?.id}}</span>
                    </td>
                    <td class="text-center py-2">
                        <span class="text-center ml-2">{{sus?.service}}</span>
                    </td>
                    <td class="text-center py-2">
                        <span class="text-center ml-2">{{sus?.status}}</span>
                    </td>
                    <td class="text-center py-2">
                        <span class="text-center ml-2">{{sus?.client?.name}}</span>
                    </td>
                    <td class="text-center py-2">
                        <span class="text-center ml-2">{{sus?.payment_method}}</span>
                    </td>
                    <td class="text-center py-2">
                        <span class="text-center ml-2">{{sus?.address?.address}}</span>
                    </td>
                    <td class="text-center py-2">
                        <span class="text-center ml-2">{{sus?.address?.floor}}</span>
                    </td>
                    <td class="text-center py-2">
                        <span class="text-center ml-2">{{sus?.address?.apartment}}</span>
                    </td>
                    <td class="text-center py-2">
                        <span class="text-center ml-2">{{sus?.address?.city}}</span>
                    </td>
                    <td class="text-center py-2">
                        <span class="text-center ml-2">{{sus?.original_date | date: format}}</span>
                    </td>
                    <td class="text-center py-2">
                        <span class="text-center ml-2">{{sus?.installation_date | date: format}}</span>
                    </td>
                    <td class="text-center py-2">
                        <span class="text-center ml-2">{{sus?.install_price}}</span>
                    </td>
                    <td class="text-center py-2">
                        <span class="text-center ml-2">{{sus?.periodic_price}}</span>
                    </td>
                    <td class="text-center py-2">
                        <span class="text-center ml-2">{{sus?.veraz_score}}</span>
                    </td>
                </tr>
            </tbody>
            <ng-template #other_content2>
                <tbody class="bg-gray-200">
                    <tr class="bg-white border-4 border-gray-200 text-sm">
                        <td colspan="14" class="text-center">
                            <span class="text-xl text-center ml-2 font-semibold">No hay datos</span>
                        </td>
                    </tr>
                </tbody>
            </ng-template>
        </table>
    </form>
</div>