import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Subscription} from '../../subscription/interfaces/subscription';
import {UploadResponse} from '../interfaces/upload-response';
import {Payment} from '../interfaces/payment';
import {Invoice} from '../../client/interfaces/invoice';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private apiUrl = environment.api_url;
  constructor(private httpClient: HttpClient) { }

  getBankPayments(period) {
    const requestOptions: Object = {
      responseType: 'blob'
    };
    const data = {
      period
    };
    return this.httpClient.post<any>(`${this.apiUrl}payments/generateBankFile`, data, requestOptions)
  }
  getBankPaymentsPreview(period) {
    const data = {
      period
    };
    return this.httpClient.post<any>(`${this.apiUrl}payments/generateBankFilePreview`, data)
  }

  sendBankFilePreview(formData) {
    return this.httpClient.post<UploadResponse>(`${this.apiUrl}payments/uploadBankFilePreview`, formData)
  }

  getInvoices(filters) {
    const data = {
      filters: filters ?? []
    };
    return this.httpClient.post<Invoice[]>(`${this.apiUrl}getInvoices`, data);
  }
}
